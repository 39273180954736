import React from 'react'
import Header from '../../Components/Headers/Header';
import './style.scss'
import CareerSupportLogo from '../../Images/careersupport.svg';
import partnerImage from '../../Images/partnerimage.jpg'
import GetInternImage from '../../Images/getIntern.svg'
import Mentorship from '../../Images/careerMentorship.svg';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import ScrollBar from '../../Components/ScrollBar/ScrollBar';


const Career = () => {
  const navigate = useNavigate();

  const CareerSupport = () => {
    return (
      <div className="career-support-container">
        <div className="text-content">
          <h1> Varahi Digital Learning</h1>
          <h2>Career support</h2>
          <p>Envision a great future and make it a reality!</p>
        </div>
        <div className="image-content">
          <img src={CareerSupportLogo} alt="Career Support" />
        </div>
      </div>
    );
  };

  const LiveProjects = () => {
    // const handleOnClick = ()=>{
    //     console.log("clicked")
    // }
    return (
      <div className="live-projects-container">
        <div className="text-content">
          <h2>Live Projects</h2>
          <h1>Live Projects with partner companies</h1>
          <p>
            Gain hands-on experience with real-world scenarios and projects that mirror the challenges you'll encounter as an employee. Our dedicated team collaborates with top companies in the industry to provide you with a comprehensive understanding of essential skills.
          </p>
          {/* <button onClick={handleOnClick}>Contact for more info</button> */}
        </div>
        <div className="image-content">
          <img src={partnerImage} alt="Live Projects" />
        </div>
      </div>
    );
  };

  const InternshipProgram = () => {
    // const handleOnClick = ()=>{
    //     console.log("clicked")
    // }
    return (
      <div className="live-projects-container">
        <div className="image-content">
          <img src={GetInternImage} alt="Live Projects" />
        </div>
        <div className="text-content">
          <h2>Internship</h2>
          <h1>Get Internship with
            our partner company</h1>
          <p>
            Our partner companies enthusiastically welcome Vahari Digital Learning  students as interns, opening a sea of opportunities. We collaborate with top companies to provide the best experiences for our students.
          </p>
          {/* <button onClick={handleOnClick}>Intern With Us</button> */}
        </div>

      </div>
    );
  };

  const CareerMentorship = () => {
    const handleOnClick = () => {
      navigate('/contact');
    }
    return (
      <div className="career-mentorship-container">
        <div className="text-content">
          <h2>1-1 Career Mentorship</h2>
          <div className="section">
            <h3>Career Resources</h3>
            <p>
              Get expert career guidance from our counselors to help you make smart and informed decisions for your future.
            </p>
          </div>
          <div className="section">
            <h3>Career Skills Workshops</h3>
            <p>
              Engage with our experts and join top-tier career and skills workshops to become a highly employable asset in leading companies.
            </p>
          </div>
          <div className="section">
            <h3>CV/LinkedIn Review</h3>
            <p>
              Obtain insightful feedback from resume and LinkedIn experts to maximize your exposure and seize every opportunity that comes your way.
            </p>
          </div>
          <button onClick={handleOnClick} className='serviceButton'>Contact for more info</button>
        </div>
        <div className="image-content">
          <img src={Mentorship} alt="Career Mentorship" />
        </div>
      </div>
    );
  };


  return (
    <div>
      <Header />
      <CareerSupport />
      <LiveProjects />
      <InternshipProgram />
      <CareerMentorship />
      <Footer />
      <ScrollBar />
    </div>
  )
}

export default Career
