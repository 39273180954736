import React, { useState, useEffect } from 'react';
import './styles.scss';
import curriculumData from './CurriculumData';

const Curriculum = ({ skill }) => {
    const [openSection, setOpenSection] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(skill);
    const [selectedCurriculum, setSelectedCurriculum] = useState([]);

    useEffect(() => {
        // Assuming curriculumData is structured like { skills: { skillName: [{ sections }] } }
        if (selectedSkill && curriculumData[selectedSkill]) {
            setSelectedCurriculum(curriculumData[selectedSkill]);
            console.log('::::::::::::::::::::', curriculumData[selectedSkill])
        } else {
            setSelectedCurriculum([]);
        }
    }, [selectedSkill]);

    const toggleSection = (sectionIndex) => {
        if (openSection === sectionIndex) {
            setOpenSection(null);
        } else {
            setOpenSection(sectionIndex);
        }
    };

    const renderContent = (content) => {
        return (
            <ul>
                {content.map((item, index) => {
                    if (typeof item === 'string') {
                        return <li key={index}>{item}</li>;
                    } else {
                        return (
                            <li key={index}>
                                <h4>{item.title}</h4>
                                {renderContent(item.content)}
                            </li>
                        );
                    }
                })}
            </ul>
        );
    };

    return (
        <div className="curriculum">
            <h2>Curriculum For {selectedSkill.toUpperCase()}</h2>
            {selectedCurriculum.map((section, index) => (
                <div key={index} className="section">
                    <div className="section-title" onClick={() => toggleSection(index)}>
                        {section.title}
                        <span className="toggle-icon">{openSection === index ? '-' : '+'}</span>
                    </div>
                    {openSection === index && (
                        <div className="section-content">
                            {renderContent(section.content)}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Curriculum;
