import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import Career from './Pages/CareerSupport';
import Policy from './Pages/PrivacyPolicy/Policy';

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/careersupport" element={<Career />} />
            <Route path="/privacy-policy" element={<Policy />} />

        </Routes>
    );
}

export default AppRoutes;
