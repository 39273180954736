import React  from 'react'

const Footer = () => {
  return (
    <footer style={{ textAlign: 'center', padding: '20px', borderTop: '1px solid #ddd' }}>
    <p>© Copyright Varahi Digital Learning   @2024 | <a href="/privacy-policy" style={{ color: 'blue' }}>Privacy Policy</a></p>
    <p>All rights reserved by SAVVYNXT TECHNOLOGIES PVT LTD</p>
  </footer>
  )
}

export default Footer;