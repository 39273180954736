import React, { useState, useEffect } from 'react';
import './styles.scss';

const ScrollBar = () => {
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, [showScroll]);
    return (
        <div className={`scroll-top ${showScroll ? 'show' : ''}`} onClick={scrollTop}>
            &#8679;
        </div>
    )
}

export default ScrollBar