import React from 'react';
import Header from '../../Components/Headers/Header';
import ScrollBar from '../../Components/ScrollBar/ScrollBar';
import './styles.scss';

const Policy = (props) => {
  return (
    <>
      <Header />
      <div  className='policy_page' >
        <h1>Privacy Policy</h1>
        <p>By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website.</p>
        <p>Your privacy is critically important to us.</p>
        <address>
          <strong>VARAHI DIGITAL LEARNING</strong><br />
          VARAHI DIGITAL LEARNING Technologies, 1st floor Sri Swaroopam building, 7-1-414/1, beside Hanuman temple, East Srinivas Nagar Colony, Srinivasa Nagar, Sanjeeva Reddy Nagar, Hyderabad, Telangana, India<br />
          +91-9686568911
        </address>
        <p>
          It is Varahi Digital Learning, SAVVYNXT Technologies policy to respect your privacy regarding any information we may collect while operating our website.
          This Privacy Policy applies to <a href="https://varahi360.com/">https://varahi360.com/</a> (hereinafter, "us", "we", or "https://varahi360.com/").
          We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website.
          We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information,
          and under what circumstances we may disclose the information to third parties.
          This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.
          This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website.
          Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.
        </p>
        <h2>Website Visitors</h2>
        <p>
          Like most website operators, Varahi Digital Learning collects non-personally-identifying information of the sort that web browsers and servers typically make available,
          such as the browser type, language preference, referring site, and the date and time of each visitor request.
          Varahi Digital Learning purpose in collecting non-personally identifying information is to better understand how Varahi360, Varahi Digital Learning and SAVVYNXT Technologies visitors use its website.
          From time to time, Varahi Digital Learning may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
          Varahi Digital Learning also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://varahi360.com/.
          Varahi Digital Learning only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.
        </p>
        <h2>Gathering of Personally-Identifying Information</h2>
        <p>
          Certain visitors to Varahi Digital Learning, SAVVYNXT Technologies websites choose to interact with Varahi360, Varahi Digital Learning, SAVVYNXT Technologies in ways that require Varahi Digital Learning, SAVVYNXT Technologies to gather personally-identifying information.
          The amount and type of information that Varahi Digital Learning gathers depend on the nature of the interaction.
          For example, we ask visitors who sign up for a blog at https://varahi360.com/ to provide a username and email address.
        </p>
        <h2>Security</h2>
        <p>
          The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.
          While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
        </p>
        <h2>Advertisements</h2>
        <p>
          Ads appearing on our website may be delivered to users by advertising partners, who may set cookies.
          These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer.
          This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you.
          This Privacy Policy covers the use of cookies by Varahi Digital Learning, SAVVYNXT Technologies, Varahi360 and does not cover the use of cookies by any advertisers.
        </p>
        <h2>Links To External Sites</h2>
        <p>
          Our Service may contain links to external sites that are not operated by us.
          If you click on a third party link, you will be directed to that third party's site.
          We strongly advise you to review the Privacy Policy and the terms and conditions of every site you visit.
          We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.
        </p>
        <h2>Google AdWords for Remarketing</h2>
        <p>
          https://varahi360.com/ uses the remarketing services to advertise on third party websites (including Google) to previous visitors to our site.
          It could mean that we advertise to previous visitors who haven't completed a task on our site, for example using the contact form to make an inquiry.
          This could be in the form of an advertisement on the Google search results page or a site in the Google Display Network.
          Third-party vendors, including Google, use cookies to serve ads based on someone's past visits.
          Of course, any data collected will be used in accordance with our own privacy policy and Google's privacy policy.
        </p>
        <p>
          You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.
        </p>
        <h2>Your Information is Secure</h2>
        <p>
          Varahi Digital Learning and SAVVYNXT Technologies Privacy Policy - Your Information is secure with us. We will not share any of your details to third party companies.
        </p>
        <p>
          Our Website links to other websites that may collect personally identifiable information about you.
          Varahi Digital Learning, SAVVYNXT Technologies is not responsible for the privacy practices or the content of those linked websites.
        </p>
      </div>
      <ScrollBar />
    </>
  )
}


export default Policy