import React from 'react';
import './style.scss';
import DreamJob from '../../Images/dreamJob.jpeg';
import { useNavigate } from 'react-router-dom';

const DreamJobSection = () => {
  const navigate = useNavigate();

  const handleWhatsAppClick = () => {
    const phoneNumber = '9490242868';
    const whatsappURL = `https://wa.me/${phoneNumber}`;
    window.open(whatsappURL, '_blank');
  };

  const handleService = () => {
    navigate('/Services');
  }


  return (
    <section className="dream-job-section">
      <div className="content">
        <h1>GET YOUR DREAM JOB/INTERNSHIP IN 90 DAYS</h1>
        <h2>WANT TO GET PLACED AS A SOFTWARE ENGINEER?</h2>
        <ul className="steps">
          <li>
            <div className="icon">■</div>
            <span>Enroll with us.</span>
          </li>
          <li>
            <div className="icon">■</div>
            <span>Learn coding from the best mentors.</span>
          </li>
          <li>
            <div className="icon">■</div>
            <span>Make amazing projects.</span>
          </li>
          <li>
            <div className="icon">■</div>
            <span>Get Placed.</span>
          </li>
        </ul>
        {/* <p className="fee">
          Fee Starting: <span className="current-fee">₹ 5999/-</span>{' '}
          <span className="old-fee">₹ 14999/-</span>
        </p> */}
        <p className="batch-info">
          Next Live Batch: <span>15th August, 2024 (Just Started, Enroll Now)</span>
        </p>
        <div className="buttons">
          <button className="view-courses" onClick={handleService}>View Courses</button>
          <button className="whatsapp-chat" onClick={handleWhatsAppClick}>Whatsapp Chat</button>
        </div>
      </div>
      <div className="image-container">
        <img src={DreamJob} alt="Dream Job Path" />
      </div>
    </section>
  );
};

export default DreamJobSection;
