import React, { useState } from 'react';
import Header from "../../Components/Headers/Header";
import { skills } from './skills';
import { tools } from './skills';
import ContentPage from './ContentPage';
import Footer from '../../Components/Footer/Footer';
import ScrollBar from '../../Components/ScrollBar/ScrollBar';

import './styles.scss';
import icon1 from '../../Images/icon1.png';
import icon2 from '../../Images/icon2.png';
import icon3 from '../../Images/icon3.png';
import icon4 from '../../Images/icon4.png';
import why1 from '../../Images/whyus1.png';
import why2 from '../../Images/why2.png';
import why3 from '../../Images/why3.png';
import why4 from '../../Images/why4.png';
import why5 from '../../Images/why5.png';
import why6 from '../../Images/why6.png';
import why7 from '../../Images/why7.png';
import why8 from '../../Images/why8.png';

import aws from '../../Images/aws.jpg';
import Java from '../../Images/java.png';
import Azure from '../../Images/azure.png';
import DevOps from '../../Images/DevOps.png';
import ReactJS from '../../Images/reactjs.png';
import fullStack from '../../Images/fullstack.jpg';
import sales from '../../Images/sales.jpg';
import azureDevops from '../../Images/AzureDevops.png';
import DataScience from '../../Images/dataScience.jpg';
import Python from '../../Images/python_course.jpg';
import Ai from '../../Images/AI_course_img.jpg';
import Testing from '../../Images/testing.png';
import Sre from '../../Images/sre.png';
import PowerBi from '../../Images/powerBi.jpg';
import Tableau from '../../Images/tableau.png';
import BusinessAnalyst from '../../Images/ba_courseimg.jpg';
import ServiceNow from '../../Images/serviceNowImf.jpg';
import Sql from '../../Images/sql_img.jpg';
import DataAnalysts from '../../Images/dataAnalysts.png';











// Sample data for the program highlights
const highlights = [
  {
    icon: icon1,
    title: 'Top-Notch Faculty',
    description: 'Trainers at Vahari Digital Learning are passionate about training, and carry 12+ years of industry experience.'
  },
  {
    icon: icon2,
    title: 'Exhaustive Course Curriculum',
    description: 'Our industry-relevant course curriculum is tailored to provide practical exposure with the theory.'
  },
  {
    icon: icon3,
    title: 'Real-life Projects and Bootcamps',
    description: 'Learners will work on real-life test case scenarios from various domains to get application knowledge.'
  },
  {
    icon: icon4,
    title: 'Guaranteed Job Interviews',
    description: 'Participants in the placement pool will get guaranteed job interviews across our 2000+ partner companies until they receive the first job offer.'
  }
];

const courseAvaliable = [
  {
    icon: aws,
    title: 'aws',
    name: 'AWS',

  },
  {
    icon: Java,
    title: 'java',
    name: 'Java',

  },
  {
    icon: Azure,
    title: 'azure',
    name: 'Azure',
  },
  {
    icon: DevOps,
    title: 'devOps',
    name: 'Devops',
  },
  {
    icon: ReactJS,
    title: 'reactJS',
    name: 'React JS',
  },
  {
    icon: fullStack,
    title: 'fullStack',
    name: 'Full Stack',
  },
  {
    icon: sales,
    title: 'salesForce',
    name: 'SalesForce',
  },
  {
    icon: azureDevops,
    title: 'azureDevOps',
    name: 'Azure Devops',
  }, 
  {
    icon: DataScience,
    title: 'dataScience',
    name: 'Data Science',
  },
  {
    icon: Python,
    title: 'python',
    name: 'Python',
  },
  {
    icon: Ai,
    title: 'artificialIntelligence',
    name: 'Artificial Intelligence',

  },
  {
    icon: Testing,
    title: 'testing',
    name: 'Testing',

  },
  {
    icon: Sre,
    title: 'sre',
    name: 'SRE',

  },
  {
    icon: PowerBi,
    title: 'powerBi',
    name: 'Power BI',

  },
  {
    icon: Tableau,
    title: 'tableau',
    name: 'Tableau',

  },
  {
    icon: BusinessAnalyst,
    title: 'businessAnalyst',
    name: 'Business Analyst',

  },
  {
    icon: ServiceNow,
    title: 'servicesNow',
    name: 'Services Now',

  },
  {
    icon: Sql,
    title: 'sql',
    name: 'SQL',

  },
  {
    icon: DataAnalysts,
    title: 'dataAnalysts',
    name: 'Data Analysts',

  },
];

// Sample data for the projects
export const projects = [
  {
    level: 'Beginner',
    title: 'To-Do List App',
    description: 'Build a simple to-do list app using ReactJS for the front-end and Java for the back-end. The app should allow users to create and manage tasks, mark tasks as complete and delete tasks.'
  },
  {
    level: 'Intermediate',
    title: 'Recipe App',
    description: 'Build a recipe app using ReactJS for the front-end and Java for the back-end. The app should allow users to search for recipes, view recipe details, and save their favorite recipes.'
  },
  {
    level: 'Intermediate',
    title: 'E-Commerce Store',
    description: 'Build an e-commerce store using ReactJS for the front-end and Java for the back-end. The store should allow users to browse products, add products to their cart and checkout.'
  },
  {
    level: 'Advanced',
    title: 'Weather App',
    description: 'Build a weather app using ReactJS for the front-end and Java for the back-end. The app should allow users to enter a location and get the current weather conditions for that location.'
  },
  {
    level: 'Advanced',
    title: 'Expense Tracker',
    description: 'Build an expense tracker app using ReactJS for the front-end and Java for the back-end. The app should allow users to enter their expenses, categorize them, and view reports on their spending.'
  },
  {
    level: 'Advanced',
    title: 'Income Tax Application',
    description: 'Build an income tax application using ReactJS for the front-end and Java for the back-end. The project will also involve choosing an appropriate database, designing an API, and deploying the application to a server.'
  }
];


const features = [
  {
    icon: why1,
    title: 'Industry based course curriculum',
  },
  {
    icon: why2,
    title: 'Experienced faculty from IIT, IIM & ISB',
  },
  {
    icon: why3,
    title: 'Work hands on with 2+ real life projects',
  },
  {
    icon: why4,
    title: 'Dedicated placement cell for Placement Assistance',
  },
  {
    icon: why5,
    title: 'Project code stored on Github & Deployed on Streamlit',
  },
  {
    icon: why6,
    title: 'Dedicated case studies support team',
  },
  {
    icon: why7,
    title: 'Lifetime E-Learning access',
  },
  {
    icon: why8,
    title: 'Get access to free Guest Lectures & Webinars',
  },
];


const Services = () => {
  const [skill, setSkill] = useState('');
  const [servicePage, setServicePae] = useState(true);



  const handleSkillClick = (skill) => {
    console.log('::: choosed skill :::', skill);
    setSkill(skill);

    setServicePae(false);
  };

  const handleGoBackToSetrvices = () => {
    setServicePae(true);
  }

  const ProgramHighlights = ({ highlights }) => (
    <div className="program-highlights">
      <h2>{'Program Highlights'}</h2>
      <div className="highlight-cards">
        {highlights.map((highlight, index) => (
          <div key={index} className="highlight-card">
            <img src={highlight?.icon} alt={highlight.title} />
            <h3>{highlight.title}</h3>
            <p>{highlight.description}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const ProjectsSection = () => {
    return (
      <div className="projects-section">
        <h2>Projects</h2>
        <div className="projects-container">
          {projects.map((project, index) => (
            <div className="project-card" key={index}>
              <div className="project-level">{project.level}</div>
              <div className="project-title">{project.title}</div>
              <div className="project-description">{project.description}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const FeaturesSection = () => {
    return (
      <div className="why-excelr">
        <h2>Why Us?</h2>
        <div className="benefits">
          {features.map((benefit, index) => (
            <div key={index} className="benefit">
              <img src={benefit.icon} alt={benefit.title} />
              <p>{benefit.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const SkillsSection = () => {
    return (
      <div className="skills-section">
        <h2>Skills Covered</h2>
        <div className="skills-container">
          {skills.map((skill, index) => (
            <div className="skill-item" key={index}>
              <img src={skill.icon} alt={`${skill.name} icon`} className="skill-icon" onClick={() => { handleSkillClick(skill.name) }} />
              <p>{skill.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ToolSection = () => {
    return (
      <div className="skills-section">
        <h2>Tools Covered</h2>
        <div className="skills-container">
          {tools.map((skill, index) => (
            <div className="skill-item" key={index}>
              <img src={skill.icon} alt={`${skill.name} icon`} className="skill-icon" />
              <p>{skill.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const CourseAvaliable = ({ courseAvaliable }) => (
    <div className="program-highlights">
      <h2>{'Courses Avaliable'}</h2>
      <div className="highlight-cards">
        {courseAvaliable.map((highlight, index) => (
          <div key={index} className="highlight-card">
            <img src={highlight?.icon} alt={highlight?.title} />
            <h3>{highlight?.name}</h3>
            <p>For More Details </p>
            <button className='serviceButton' onClick={() => { handleSkillClick(highlight?.title) }}>Click Here</button>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    servicePage ?
      <>

        <Header />
        <div className="services">
          <ProgramHighlights highlights={highlights} />
          <CourseAvaliable courseAvaliable={courseAvaliable} />

          <SkillsSection />
          <ToolSection />
          <ProjectsSection />
          <FeaturesSection />
          <Footer />
          <ScrollBar />
        </div>
      </>
      :
      <>
        <ContentPage skill={skill} />
        <button className='serviceButton curriculumButton' onClick={handleGoBackToSetrvices}>{'GO Back'}</button>
        <Footer />
        <ScrollBar />
      </>
  );
};






export default Services;
