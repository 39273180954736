import React from 'react';
import './style.scss';
import about from '../../Images/about.jpg'
import Header from '../../Components/Headers/Header';
import DreamJobSection from './DreamJobSection';
import LearnBuildGetJob from './LearnBuildGetJob';
import Footer from '../../Components/Footer/Footer';
import ScrollBar from '../../Components/ScrollBar/ScrollBar';

const About = () => {

  const ExperienceSection = () => {
    return (
      <section className="experience-section">
        <div className="experience-content">
          <div className="experience-text">
            <h1><span className="years">10+</span> Years of Experience</h1>
            <h2>We Don't Just Train, We Build Careers !!</h2>
            <p>
              As a new-age training institute, we at Vahari360 understand the constantly changing nature of the business and emerging needs across the globe. Keeping industry skill requirements in mind, we offer a wide gamut of technical and management courses as well as courses in emerging technologies. The curriculum of each course has been meticulously designed to match the contemporary needs of the industry. We offer different levels of courses from beginner to advanced, ensuring that we meet the talent requirements of both students and working professionals. Our courses aim to prepare students for future advancements in technology, strategic thinking, and planning, ensuring that they are future-ready and build a lucrative career ahead.
            </p>
          </div>
          {/* <div className="experience-video">
                    <img src="path_to_your_image" alt="Video Thumbnail" />
                    <div className="play-button">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M3 22v-20l18 10-18 10z"/>
                        </svg>
                    </div>
                </div> */}
        </div>
      </section>
    );
  };


  return (
    <>
      <Header />
      <div className="about-page">
        <div className="content">
          <p>
            Vahari Digital Learning is a rising platform dedicated to helping students secure their dream jobs. Our mentors teach in a unique and highly effective way, excelling in their respective fields. We provide excellent placement assistance with the help of a dedicated HR team who will support you in every way to achieve your career goals.
            Additionally, you will have the opportunity to interact with mentors and fellow students through numerous engaging events beyond the regular studies. The best part is, we offer all of this at the most affordable fee in the market.
          </p>
        </div>
        <div className="image">
          <img src={about} alt="Developer" />
        </div>
      </div>
      <ExperienceSection />
      <DreamJobSection />
      <LearnBuildGetJob />
      <Footer />
      <ScrollBar />
    </>
  );
};

export default About;
