import skill1 from '../../Images/Skill_1.png'
import skill2 from '../../Images/Skill_1.png'
import skill3 from '../../Images/Skill_2.png'
import skill4 from '../../Images/Skill_3.png'
import skill5 from '../../Images/Skill_4.png'
import skill6 from '../../Images/skill_5.png'
import skill7 from '../../Images/Skill_6.png'
import skill8 from '../../Images/Skill_7.png'
import skill9 from '../../Images/skill_8.png'
import skill_10 from '../../Images/skill_9.png'
import skill_11 from '../../Images/skill_10.png'
import skill_12 from '../../Images/python_img.jpg'


import Eclipse from '../../Images/Tools_1.png'
import Postman from '../../Images/Tools_2.png'
import SpringToolSuite from '../../Images/Tools_3.png'
import MySQLworkbench from '../../Images/Tools_4.png'
import VSCode from '../../Images/Tools_5.png'
import Maven from '../../Images/Tools_6.png'
import Spring from '../../Images/Tools_7.png'
import SpringBoot from '../../Images/Tools_8.png'
import Hibernate from '../../Images/Tools_9.png'
import RESTAPI from '../../Images/Tools_10.png'
import GIT from '../../Images/Tools_11.png'
import ApacheTomcat from '../../Images/Tool_12.png'





export const skills = [
    { name: 'Core Java', icon: skill1  },
    { name: 'Advanced Java', icon: skill2 },
    { name: 'HTML', icon: skill3 },
    { name: 'CSS', icon: skill4 },
    { name: 'JavaScript', icon: skill5},
    { name: 'Bootstrap', icon: skill6 },
    { name: 'MySQL', icon: skill7 },
    { name: 'ReactJS', icon: skill8 },
    { name: 'JSP', icon: skill9 },
    { name: 'Servlet', icon: skill_10},
    { name: 'JDBC', icon: skill_11 },
    { name: 'Python', icon: skill_12 },

  ];

  export const tools = [
    { name: 'Eclipse', icon: Eclipse  },
    { name: 'Postman', icon: Postman },
    { name: 'Spring Tool Suite', icon: SpringToolSuite },
    { name: 'MySQL workbench', icon: MySQLworkbench },
    { name: 'VS Code', icon: VSCode},
    { name: 'Maven', icon: Maven },
    { name: 'Spring', icon: Spring },
    { name: 'Spring Boot', icon: SpringBoot },
    { name: 'Hibernate', icon: Hibernate },
    { name: 'REST API', icon: RESTAPI},
    { name: 'GIT', icon: GIT },
    { name: 'Apache Tomcat', icon: ApacheTomcat },

  ];