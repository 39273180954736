import React from "react";
import { useNavigate } from 'react-router-dom';

const CourceDetails = ({ overview, highlights, details, whoCanApply,skill }) => {
  const navigate = useNavigate();

  const handleContactBtn = () => {
    navigate('/contact');
  }

  return (
    <div className="course-container">
      <div className="course-overview">
        <h2>Overview</h2>
        <p>
          Start your developer career by selecting our {skill} Course. {skill != 'testing' ?  'is a widely-used programming language for coding web applications' : ''} . It has been a popular choice among developer for over two decades, . Learn our {skill} course from industrial experts with hands-on Experience.
        </p>
        <div>
          <h3>Key Highlights</h3>
          <ul>
            <li>Hands-On Experience: Gain practical skills with coding real world scenarios.</li>
            <li>Real Projects: Dealing with many real time Projects learning with Industrial Experts from scratch.</li>
            <li>Industry Standards: Learn best practices in coding.</li>
          </ul>
        </div>
      </div>
      <div className="applicants-info">
        <h3>Who Can Apply?</h3>
        <ul>
          <li>Aspiring freshers interested in Developer role.</li>
          <li>Professionals in IT and related fields seeking to enhance their programming skills.</li>
          <li>Students and hobbyists looking to build professional-grade.</li>
        </ul>
      </div>
      <div className="corporate-training-info">
        <h3>Corporate Training</h3>
        <p>We give Corporate Employees the Training They Need to Learn & Lead</p>
        <button className="serviceButton" onClick={handleContactBtn}>CONTACT US</button>
        <div className="training-details">
          <h4>Details</h4>
          <ul>
            <li>Flexible Timings</li>
            <li>36 Hours Training</li>
            <li>Certification</li>
            <li>24/7 Support</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CourceDetails;