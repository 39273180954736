import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../Images/main_logo.png';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef(null);
    const toggleRef = useRef(null);
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogoOnClick = () => {
        navigate('/');
        window.location.reload();
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current && !menuRef.current.contains(event.target) &&
            toggleRef.current && !toggleRef.current.contains(event.target)
        ) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    return (
        <header>
            <div>
                <img src={logo} className="Headlogo" alt="Logo" onClick={handleLogoOnClick} />
            </div>
            <nav ref={menuRef} className={menuOpen ? 'open' : ''}>
                <ul>
                    <li><Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link></li>
                    <li><Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About</Link></li>
                    <li><Link to="/services" className={location.pathname === '/services' ? 'active' : ''}>Programs</Link></li>
                    <li><Link to="/careersupport" className={location.pathname === '/careersupport' ? 'active' : ''}>Career Support</Link></li>
                    <li><Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact</Link></li>
                </ul>
            </nav>
            <div ref={toggleRef} className="menu-toggle" onClick={toggleMenu}>
                <div className="dot-grid">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                </div>
            </div>
        </header>
    );
};

export default Header;
