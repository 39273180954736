import React from "react";
import './styles.scss';
import worried from '../../Images/worried.png'; 
import Header from "../../Components/Headers/Header";
import { useNavigate } from 'react-router-dom'; 
import Footer from "../../Components/Footer/Footer";

function Home() {
    const navigate = useNavigate(); 

    const handleClick = ()=>{
        navigate('/Services'); 
    }

    return (
        <div className="container">
            <Header />
            <main className="main-home">
            <div className="image">
                    <img src={worried} alt="Worried" />
                </div>
                <div className="content">
                    <p className="subheading">All my friends enjoy comfortable, high-paying careers. Can I also secure a lucrative IT position?</p>
                    <h1>DISCOURAGED THINKING YOU CANNOT LAND A HIGH-PAYING IT JOB?</h1>
                    <p className="subheading-1">Put your worries to rest. This comprehensive online and offline training program equips you with the skills to get noticed by leading software companies you have always dreamt of joining. Start your journey towards earning an average salary of 4 lakh per annum or more Guaranteed success!</p>
                    <button onClick={handleClick} className="serviceButton">YES! I WANT TO JOIN</button>
                </div>
           
            </main>
            <Footer />
        </div>
    );
}

export default Home;
