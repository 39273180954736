// src/Pages/About/LearnBuildGetJob.js
import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';

const LearnBuildGetJob = () => {
  const navigate = useNavigate();

  const getInTouch = () => {
    navigate('/Contact');
  }
  return (
    <section className="learn-build-get-job">
      <div className="content">
        <h1>Learn. Build. Get Job.</h1>
        <p>50,000+ uplifted through our hybrid classroom & online training, enriched by real-time projects and job support.</p>
      </div>
      <div className="button-container">
        <button className="serviceButton" onClick={getInTouch}>Get In Touch</button>
      </div>
    </section>
  );
};

export default LearnBuildGetJob;
