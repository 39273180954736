
const curriculumData = {

    reactJS: [
        {
            title: "Applications & Web Technologies",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Building Blocks of Web Design - HTML ",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basics of HTML",
                        "Core HTML Elements",
                        "Structuring Web Content",
                        "Interactive Forms",
                        "Embedding Multimedia",
                        "Modern HTML Elements",
                        "Dynamic HTML with APIs",
                        "Reusable Web Components",
                        "Enhancing Web Accessibility",
                        "Maintaining HTML Standards",
                        "Optimizing Performance",
                        "Web Security Fundamentals",
                        "Keeping Up with HTML Evolution",

                    ]
                }
            ]
        },
        {
            title: "CSS Fundamentals",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Foundations of CSS",
                        "Applying CSS Styles",
                        "CSS Syntax",
                        "Selectors and Combinators",
                        "Understanding the Box Mode",
                        "Typography and Text Styling",
                        "Units, Values, and Colors",
                        "Specificity, Inheritance, and the Cascade",
                        "Visual Effects",
                        "Background Techniques",
                        "Advanced Border and Outline Styling",
                        "Layout Control with Overflow and Margin Collapsing",
                        "The Display Property",
                        "CSS Positioning Techniques",
                        "Floating Elements and Clearing Floats",
                        "Handling Overflow",
                        "Flexbox and Grid Layouts",
                        "Responsive Design Techniques",
                        "Animations and Transitions",
                        "Advanced Responsive Design",

                    ]
                }
            ]
        },
        {
            title: "Bootstrap Essentials-Responsive Design",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Bootstrap",
                        "Setting Up Bootstrap",
                        "Using Bootstrap CDN",
                        "Basic Grid System",
                        "Containers, Rows, and Columns",
                        "Responsive Classes",
                        "Typography and Utilities",
                        "Default Typography",
                        "HText Alignment and Display Classes",
                        "Spacing Utilities",
                        "Navigation",
                        "Navbar Dropdowns within Navbar",
                        "Alerts: Basic Alerts & Dismissible Alerts",
                        "Badges",
                        "Button Styles & Button Groups",
                        "Basic Card: Card Headers and Footers",
                        "Image Overlays in Cards",
                        "Form Controls, Form Groups, and Layout",
                        "Validation Feedback",
                        " Basic Modal Setup",
                        "Carousel with Indicators and Controls",
                        "Tooltips and Popovers",
                        " Flex Container and Items",
                        "Direction, Order, and Alignment in Flex",
                        "Visibility Classes"


                    ]
                }
            ]
        },
        {
            title: "JavaScript-Building Blocks to Advanced Scripts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to JavaScript",
                        "Functions and Scope",
                        "Control Structures and Data Handling",
                        "Strings, Dates, and Template Literals",
                        "Object-Oriented Programming in JavaScript",
                        "Deep Dive into Functions and Scope",
                        "Asynchronous JavaScript",
                        "Execution Context, Scope Chain, and Error Handling",
                        "Modules and Regular Expressions",
                        "Local Storage and Session Storage",
                        "DOM Basics",
                        "Event Handling",
                        "Advanced DOM Manipulation",
                        "Asynchronous JavaScript Revisited",
                        "Working with APIs",
                        "Advanced Function Usage",
                        "Error Handling and Regular Expressions",
                        "JavaScript Modules",
                        "Web Storage"



                    ]
                }
            ]
        },
        {
            title: "Typescript Essentials",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding TypeScript",
                        "The Core Concepts",
                        "Working with Enums",
                        "Advanced Typing Features",
                        "Iterating with TypeScript",
                        "OOP Fundamentals in TypeScript",
                        "Inheritance and Polymorphism",
                        "Interfaces: Blueprint of an Object",
                        "Abstract Classes",
                        "Namespaces",
                        "Modules: The Building Blocks"
                    ]
                }
            ]
        },
        {
            title: "React Fundamentals and ES6",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Getting Started with React",
                        "ES6 for React",
                        "Functional Components and JSX"



                    ]
                }
            ]
        },
        {
            title: "Developing With React Components",
            content: [

                {
                    title: "Topics",
                    content: [
                        "React Components and JSX Deep Dive",
                        "State and Props in React",
                        "React Hooks for Component Lifecycle"

                    ]
                }
            ]
        },
        {
            title: "Application Architecture in React",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Routing in React Applications",
                        "Form Handling in React",
                        "Global State Management with Redux"

                    ]
                }
            ]
        },
        {
            title: "Advanced React Features",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Advanced Hooks and State Management",
                        "Styling and Data Fetching"


                    ]
                }
            ]
        },
        {
            title: "Perfomance Optimization and Best Practices",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Optimizing React Applications",
                        "Testing React Components",
                        "React Best Practices"

                    ]
                }
            ]
        },
        {
            title: "Introduction to Cloud Computing for Developers",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Basics",
                        "Cloud Service Providers Overview",
                        "Cloud-based Development Environments",
                        "Deploying Applications on the Cloud"

                    ]
                }
            ]
        },
        {
            title: "DevOps for Full Stack Developers",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding DevOps",
                        "Version Control with Git",
                        "Continuous Integration/Continuous Deployment (CI/CD)",
                        "Monitoring and Feedback"

                    ]
                }
            ]
        },
        {
            title: "Infrastructure and Configuration Management",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Containers and Docker",
                        "Managing Application Infrastructure"


                    ]
                }
            ]
        },
        {
            title: "Building and Deploying Scalable Web Applications",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Scalable Application Design",
                        "Cloud-native Services for Developers",
                        "Databases in the Cloud",
                        "Security Basics in Cloud and DevOps"

                    ]
                }
            ]
        },
        {
            title: "Project Collaboration and DevOps Practices",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Agile and Scrum Methodologies",
                        "Code Review and Collaboration Tools",
                        "Automation in Development",
                        "DevOps Culture and Best Practices"

                    ]
                }
            ]
        },
    ],
    azure: [
        {
            title: "Introduction to Cloud Concepts and Azure",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Cloud Concepts",
                        "Understanding the benefits and considerations of using cloud services",
                        "Exploring Infrastructure-as-a-Service (IaaS), Platform-as-a-Service (PaaS), Software-as-a-Service (SaaS)",
                        "Differentiating between Public Cloud, Private Cloud, and Hybrid Cloud models",
                    ]
                }
            ]
        },
        {
            title: "Core Azure Services",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Azure Compute",
                        "Azure Storage",
                        "Azure Networking",
                        "Azure Database Services",
                    ]
                }
            ]
        },
        {
            title: "Azure pricing,Support and Governance",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Azure Pricing and Support",
                        "Azure Governance",
                    ]
                }
            ]
        },
        {
            title: "Managing Azure Resources",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Azure Portal and Azure CLI",
                        "Azure Management Toolsr",
                    ]
                }
            ]
        },
        {
            title: "Azure Applications Services and Advanced Topics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "App Services",
                    ]
                }
            ]
        },
        {
            title: "Advanced Azure Adminstration",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Enhanced Azure Resource Management",
                    ]
                }
            ]
        },
        {
            title: "Deep Dive into Azure Storage and Virtual Machines",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced Azure Storage Solutions",
                        "Mastering Azure Virtual Machines"
                    ]
                }
            ]
        },
        {
            title: "Advanced Azure Networking",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Implementing Complex Network Solutions",
                        "Securing Azure Networks"
                    ]
                }
            ]
        },
        {
            title: "Identity,Access Management, and Govern",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Azure Active Directory and Identity Protection",
                        "Advanced Governance and Compliance"
                    ]
                }
            ]
        },
        {
            title: "Monitoring Data Protection,and Recovery",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced Monitoring and Diagnostics",
                        "Azure Backup and Site Recovery"
                    ]
                }
            ]
        },
        {
            title: "Introduction to Azure Architechture",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Overview of Azure Architect Design",
                    ]
                }
            ]
        },
        {
            title: "Desiging Solutions and Monitoring in Azure",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Design and Monitoring in Azure",
                    ]
                }
            ]
        },
        {
            title: "Identity And Access Management(IAM) and Security",
            content: [
                {
                    title: "Topics",
                    content: [
                        "IAM Services and Security",
                    ]
                }
            ]
        },
        {
            title: "Data Storage and Business Continuity Design",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Data Storage Design",
                        "Business Continuity Design"
                    ]
                }
            ]
        },
        {
            title: "Desiging Infrastructure and MicroServices",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Design and Infrastructure",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Azure devOps",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is Azure DevOps?",
                        "Azure Boards",
                        "Azure Repos",
                        "Azure Pipelines",
                        "Creating Pipelines in Azure DevOps"
                    ]
                }
            ]
        },
        {
            title: "Agile Project Management With Azure Brands",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Agile Project Management Best Practices",
                        "Basic Concepts of Azure Boards",
                        "Connecting Boards to GitHub",
                        "Work Items and Sprints",
                        "Azure Boards Integrations"
                    ]
                }
            ]
        },
        {
            title: "Version Control with Azure Repos",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Azure Repos",
                        "Branches and Cloning in Azure Repos",
                        "Import Code from GitHub",
                        "Search Your Code in Repos",
                        "Azure Repos Integrations",
                    ]
                }
            ]
        },
        {
            title: "Continous integration/Deployment with Azure Applications",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Deploying with Azure Pipelines",
                        "CI Triggers and YAML Basics",
                        "Setting Up CI Build",
                        "Adding Tests to the Pipeline",
                        "Agents and Tasks"
                    ]
                }
            ]
        },
        {
            title: "Azure Test Plans& Artifacts",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Working with Packages in Azure Artifacts",
                        "Connection Feeds and Views in Artifacts",
                        "Connecting Azure Artifacts to Azure Pipelines",
                        "What are Azure Test Plans?",
                        "Testing Web Apps"
                    ]
                }
            ]
        },
    ],
    devOps: [
        {
            title: "Applications & Web Technologies",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                        "Web Technologies used in Projects"

                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle(SDLC)",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process"

                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices"

                    ]
                }
            ]
        },
        {
            title: "Linux For DevOps & Cloud",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Linux OS",
                        "Linux Distributions and Architecture",
                        "Command Line Interface (CLI) & Filesystem",
                        "File Management and vi Editor",
                        "Archives and Package Management",
                        "System Installation and Package Managers",
                        "Users, Groups, and Permissions",
                        "Networking Basics: IP Address, Protocols, & Ports",
                        "Firewalls and Security Measures",
                        "Load Balancers"

                    ]
                }
            ]
        },
        {
            title: "Version Control - GIT,GITHUB",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Version Control System",
                        "Centralised vs Distributed Version Control System",
                        "Git & GitHub Introduction",
                        "Git Workflow",
                        "GitHub for Collaboration",
                        "Git Branching Model",
                        "Git Merging and Pull Requests",
                        "Git Rebase",
                        "Handling Detached Head and Undoing Changes",
                        "Advanced Git Features: Git Ignore, Tagging"

                    ]
                }
            ]
        },
        {
            title: "Containerisation - Docker",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Containerisation",
                        "Monolithic vs Microservices Architecture",
                        "Introduction to Virtualisation and Containerisation",
                        "Docker Architecture",
                        "Setting up Docker",
                        "Docker Registry, Images, and Containers",
                        "Running Docker Containers",
                        "Docker Volumes and Networks",
                        "Docker Logs and Tags",
                        "Dockerize Applications and Docker Compose"

                    ]
                }
            ]
        },
        {
            title: "CI & CD-Jenkins",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to CI/CD & Jenkins",
                        "Benefits and Requirements of CI/CD",
                        "Setting Up Jenkins",
                        "Build Tools and Repository Management",
                        "Jenkins Job Configuration",
                        "Creating and configuring jobs in Jenkins for automated builds.",
                        "Jenkins Pipelines and Pipeline as Code",
                        "Utilizing Jenkins Plugins",
                        "Continuous Deployment with Jenkins",
                        "Jenkins Integrations"

                    ]
                }
            ]
        },
        {
            title: "Code Quality with SonarQube",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to SonarQube",
                        "Core Features",
                        "Setup and Use",
                    ]
                }
            ]
        },
        {
            title: "Artifact Storage with Nexus Respository",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Nexus Repository",
                        "Key Features",
                        "Installation and Configuration",
                        "Using Nexus Repository",
                        "Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Kubernetes and Orchestration",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to High Availability",
                        "Introduction to Container Orchestration",
                        "Container Orchestration Tools",
                        "Overview of Kubernetes",
                        "Kubernetes Architecture",
                    ]
                }
            ]
        },
        {
            title: "Core Components of Kubernetes",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Components of Kubernetes",
                        "Kubernetes Objects",
                        "Pods",
                        "Replica Setst",
                        "Deployments",
                    ]
                }
            ]
        },
        {
            title: "Kubernetes Services and Networking",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Services",
                        "ClusterIP",
                        "NodePort",
                        "Load Balancer",
                        "Ingress",
                    ]
                }
            ]
        },
        {
            title: "Configuration and Storage in Kubernetes",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Config Maps",
                        "Secrets",
                        "Persistent Volume (PV) and Persistent Volume Claim (PVC)",
                        "Storage Classest",
                        "StatefulSets",
                    ]
                }
            ]
        },
        {
            title: "Kubernetes In production",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Production Clusters",
                        "Overview of AWS EKS",
                        "Setup EKS",
                        "Deploy Applications On EKS",
                        "Monitoring and Logging",
                    ]
                }
            ]
        },

        {
            title: "Introduction To Terraform",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Infrastructure as Code (IaC)",
                        "Overview of Terraform",
                        "Setup Terraform",
                        "Terraform Providers",
                        "Terraform Resources",
                    ]
                }
            ]
        },
        {
            title: "Terraform Core Concepts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Terraform State Management",
                        "Terraform Modules",
                        "Input Variables",
                        "Output Values",
                        "Terraform Workspaces",
                    ]
                }
            ]
        },
        {
            title: "Terraform Secuirty and Efficiency",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Terraform and Security",
                        "Efficient Terraform Configurations",
                        "Terraform Plan and Apply Best Practices",
                        "Debugging Terraform Configurations",
                        "Terraform Versioning and Upgrades",
                    ]
                }
            ]
        },
        {
            title: "Terraform in Practice",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Terraform for Team Collaboration",
                        "Terraform in CI/CD Pipelines",
                        "Code Organization and Module Registry",
                        "Terraform Best Practices",
                        "Monitoring and Maintenance",
                    ]
                }
            ]
        },
        {
            title: "Terraform Collaboration and version Control",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Version Control Systems in Terraform Workflows",
                        "Collaborative Workflows with Terraform",
                        "Terraform Code Reusability and Standards",
                        "Documenting Terraform Code",
                        "Introduction to Terraform Cloud for Teams",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Azure DeveOps",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is Azure DevOps?",
                        "Azure Boards",
                        "Azure Repos",
                        "Azure Pipelines",
                        "Creating Pipelines in Azure DevOps",
                    ]
                }
            ]
        },
        {
            title: "Agile Project Management with Azure Boards",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Agile Project Management Best Practices",
                        "Basic Concepts of Azure Boards",
                        "Connecting Boards to GitHub",
                        "Work Items and Sprints",
                        "Azure Boards Integrations",
                    ]
                }
            ]
        },
        {
            title: "Version Control with Azure Repos",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Azure Repos",
                        "Branches and Cloning in Azure Repos",
                        "Import Code from GitHub",
                        "Search Your Code in Repos",
                        "Azure Repos Integrations",
                    ]
                }
            ]
        },
        {
            title: "Continous Integration/Depolyment with Azure Pipelines",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deploying with Azure Pipelines",
                        "CI Triggers and YAML Basics",
                        "Setting Up CI Build",
                        "Adding Tests to the Pipeline",
                        "Agents and Tasks",
                    ]
                }
            ]
        },
        {
            title: "Azure Test Plans & Artifacts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Working with Packages in Azure Artifacts",
                        "Connection Feeds and Views in Artifacts",
                        "Connecting Azure Artifacts to Azure Pipelines",
                        "What are Azure Test Plans?",
                        "Testing Web Apps"
                    ]
                }
            ]
        },
        {
            title: "Introduction to Python as a Scripting Language",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Python as a Scripting Language",
                        "Python Collections and Sequences",
                        "Working with Python Collections",
                        "Python Functional Programming",
                        "Python File Handling"



                    ]
                }
            ]
        },
        {
            title: "Advanced Python Concepts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Python Modules and Packages",
                        "Classes in Python",
                        "Object-Oriented Programming (OOP) in Python",
                        "Exception Handling",
                        "Python Decorators and Generators"



                    ]
                }
            ]
        },
        {
            title: "Python for Automation",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Automation through Scripting Languages",
                        "Automating File System Operations",
                        "Web Scraping with Python",
                        "Automating Network Tasks",
                        "Automating API Interactions"



                    ]
                }
            ]
        },
        {
            title: "Building and Deploying Python Applications",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Building Python Applications",
                        "Testing Python Applications",
                        "Python Application Deployment",
                        "CI/CD for Python Applications",
                        "Virtual Environments and Package Management"



                    ]
                }
            ]
        },
        {
            title: "Continuous Integration and Continuous Deployment(CI/CD) with Python",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Python in CI/CD Pipelines",
                        "Automating Builds and Tests with Python",
                        "Python for Deployment Automation",
                        "Monitoring and Logging with Python",
                        "Version Control Automation with Python"



                    ]
                }
            ]
        },
        {
            title: "Introduction to SRE Fundamentals",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to SRE",
                        "Introduction to Monitoring",
                        "Introduction to Observability",
                        "SRE Roles and Responsibilities",
                        "SRE Best Practices and Principles"



                    ]
                }
            ]
        },
        {
            title: "Monitoring with Prometheus",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Prometheus",
                        "Prometheus Architecture",
                        "Monitoring with Prometheus",
                        "Scraping Metrics with Prometheus",
                        "Prometheus YAML Configs and Node Exporter"



                    ]
                }
            ]
        },
        {
            title: "Observability with Grafana",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Visualization with Grafana",
                        "Installing Grafana on a Linux Server",
                        "Grafana User Interface Overview",
                        "Creating Grafana Dashboards",
                        "Grafana with Docker"



                    ]
                }
            ]
        },
        {
            title: "Advanced Monitoring and Obesrvability",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Integrating Prometheus and Grafana",
                        "Alerting with Prometheus",
                        "Log Management and Analysis",
                        "Tracing and Distributed Tracing",
                        "Cloud Monitoring Solutions"



                    ]
                }
            ]
        },
        {
            title: "SRE Tools and Automation",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Infrastructure as Code (IaC) for SRE",
                        "CI/CD Pipelines for Reliable Deployments",
                        "SRE and DevOps: Collaboration and Tools",
                        "Automation in Incident Management",
                        "Capacity Planning and Performance Tuning"



                    ]
                }
            ]
        },
    ],
    fullStack: [
        {
            title: "Software Development Life Cycle & Application LifeCycle Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        " DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices",
                    ]
                }
            ]
        },

        {
            title: "Building Blocks of Web Design - HTML",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basics of HTML",
                        "Core HTML Elements",
                        "Structuring Web Content",
                        "Interactive Forms",
                        "Embedding Multimedia",
                        "Modern HTML Elements",
                        "Dynamic HTML with APIs",
                        "Reusable Web Components",
                        "Enhancing Web Accessibility",
                        "Maintaining HTML Standards",
                        "Optimizing Performance",
                        "Web Security Fundamentals",
                        "Keeping Up with HTML Evolution",

                    ]
                }
            ]
        },
        {
            title: "CSS Fundamental",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Foundations of CSS",
                        "Applying CSS Styles",
                        "CSS Syntax",
                        "Selectors and Combinators",
                        "Understanding the Box Model",
                        "Typography and Text Styling",
                        "Units, Values, and Colors",
                        "Specificity, Inheritance, and the Cascade",
                        "Visual Effects",
                        "Background Techniques",
                        "Advanced Border and Outline Styling",
                        "Layout Control with Overflow and Margin Collapsing",
                        "The Display Property",
                        "CSS Positioning Techniques",
                        "Floating Elements and Clearing Floats",
                        "Handling Overflow",
                        "Flexbox and Grid Layouts",
                        "Responsive Design Techniques",
                        "Animations and Transitions",
                        "Advanced Responsive Design",
                    ]
                }
            ]
        },
        {
            title: "Bootstrap Essentials - Responsive Design",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Getting Started with Bootstrap",
                        "Bootstrap Layouts and Responsiveness",
                        "Navigation and Components in Bootstrap",
                        "Advanced Bootstrap Components",
                        "Interactivity and Layout in Bootstrap",

                    ]
                }
            ]
        },
        {
            title: "JavaScript - Building Blocks to Advanced Scripts",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to JavaScrip",
                        "Functions and Scope",
                        "Control Structures and Data Handling",
                        "Strings, Dates, and Template Literals",
                        "Deep Dive into Functions and Scope",
                        "Asynchronous JavaScript",
                        "Execution Context, Scope Chain, and Error Handling",
                        "Modules and Regular Expressions",
                        "Local Storage and Session Storage",
                        "DOM Basics",
                        "vent Handling",
                        "Advanced DOM Manipulation",
                        "Asynchronous JavaScript Revisited",
                        "Working with APIs",
                        "Advanced Function Usage",
                        "Error Handling and Regular Expressions",
                        " JavaScript Modules",
                        "Web Storage",
                    ]
                }
            ]
        },
        {
            title: "React Fundamentals and ES6",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Getting Started with React",
                        "ES6 for React",
                        "Functional Components and JSX",
                    ]
                }
            ]
        },
        {
            title: "Developing with React Components",
            content: [
                {
                    title: "Topics",
                    content: [
                        "React Components and JSX Deep Dive",
                        "State and Props in React",
                        "React Hooks for Component Lifecycle",
                    ]
                }
            ]
        },
        {
            title: "Advanced React Feature",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced Hooks and State Management",
                        "Styling and Data Fetching",
                    ]
                }
            ]
        },
        {
            title: "Application Architecture in React",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Routing in React Applications",
                        "Form Handling in React",
                        "Global State Management with Redux"
                    ]
                }
            ]
        },
        {
            title: "Performance Optimization and Best Practices",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Optimizing React Applications",
                        "Testing React Components",
                        "React Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Java Basics and Fundamental",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Java",
                        "Java Development Environment",
                        "Understanding Java Technology",
                        "Java Programming Fundamentals",

                    ]
                }
            ]
        },
        {
            title: "Object-Oriented Programming in Java",
            content: [
                {
                    title: "Topics",
                    content: [
                        "OOP Principles in Java",
                        "Polymorphism and Encapsulation",
                        "Advanced Class Features",
                        "rganizing Code with Packages",
                    ]
                }
            ]
        },
        {
            title: "Data Handling and Exception Management in Java DB With Nodejs",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Working with Data Structures",
                        "File IO and Serialization",
                        "Exception Handling and Multithreading",
                    ]
                }
            ]
        },
        {
            title: "Advanced Java Development",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Java Collections and Stream API",
                        "Database Connectivity and ORM",
                        "Spring Framework and Spring Boot",
                        "Microservices and Cloud Integration",

                    ]
                }
            ]
        },
        {
            title: "Enterprise Java",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Enterprise Java",
                        "Building REST APIs with Spring Boot",
                        "Microservices with AWS Lambda",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Node.js and JavaScript Essentials",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Foundations of Node.js",
                        "JavaScript and ES6+ for Node.js",
                        "Node.js Runtime Deep Dive",
                    ]
                }
            ]
        },
        {
            title: "Web Development with Express.js and Data Handling",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Express.js Fundamentals",
                        " Data Handling in Node.js",]
                }
            ]
        },
        {
            title: " API Development and Security in Node.js",
            content: [
                {
                    title: "Topics",
                    content: [
                        "RESTful API Development",
                        "Securing Node.js Applications",
                    ]
                }
            ]
        },
        {
            title: " MongoDB Essentials and Integration with Node.js",
            content: [
                {
                    title: "Topics",
                    content: [
                        "MongoDB Basics and CRUD Operations",
                        "Aggregation and Data Modeling in MongoDB",
                        "Integrating MongoDB with Node.js",

                    ]
                }
            ]
        },
        {
            title: "Python Programming Fundamentals",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Python",
                        "Core Programming Concepts",
                    ]
                }
            ]
        },
        {
            title: "Advanced Python Concepts and Collections",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Deep Dive into Collections",
                        " Functional Programming in Python",
                        "Object-Oriented Programming (OOP)",
                    ]
                }
            ]
        },
        {
            title: "Exception Handling and File Management in Python",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Mastering Exception Handling",
                        " File Handling Essentials",
                        "Database Programming",
                    ]
                }
            ]
        },
        {
            title: "Developing Web Applications with Python ",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Getting Started with Flask",
                        "Exploring Django",
                    ]
                }
            ]
        },
        {
            title: "Automation, GUI Programming, and Version Control",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Automation and Scripting",
                        "GUI Development with TKinter",
                        "Version Control with Git",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Cloud Computing for Developers",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Basics",
                        "Cloud Service Providers Overview",
                        "Cloud-based Development Environments",
                        "Deploying Applications on the Cloud",

                    ]
                }
            ]
        },
        {
            title: "DevOps for Full Stack Developers",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding DevOps",
                        "Version Control with Git",
                        "Continuous Integration/Continuous Deployment (CI/CD)",
                        "Monitoring and Feedback",
                    ]
                }
            ]
        },
        {
            title: "Infrastructure and Configuration Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Containers and Docker",
                        "Configuration Management Tools",
                        "Managing Application Infrastructure",
                        "Serverless Computing Concepts",
                    ]
                }
            ]
        },
        {
            title: "Building and Deploying Scalable Web Applications",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Scalable Application Design",
                        "Cloud-native Services for Developers",
                        "Databases in the Cloud",
                        "Security Basics in Cloud and DevOps",

                    ]
                }
            ]
        },
        {
            title: "Project Collaboration and DevOps Practices",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Agile and Scrum Methodologies",
                        "Code Review and Collaboration Tools",
                        "Automation in Development",
                        "DevOps Culture and Best Practices",

                    ]
                }
            ]
        },
    ],
    salesForce: [
        {
            title: "Introduction to Salesforce",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is Cloud Computing?",
                        "What is CRM?",
                        "What is Salesforce",
                        "What is SaaS-Software as Service",
                        "What is PaaS-Platform as Service",
                        "What is IaaS-Infrastructure as Service ?",
                        "Salesforce Architecture",
                        "Salesforce Licenses",
                        "Salesforce Editions",
                        "Salesforce Development Life cycle"

                    ]
                }
            ]
        },
        {
            title: "Salesforce Environment and setup",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Creation of salesforce Account",
                        "Business Hours",
                        "Company information",
                        "Fiscal Year",
                        "Data Protection and Privacy",
                        "Language Settings",
                        "Manage Currencies"
                    ]
                }
            ]
        },
        {
            title: "User Interface and Search",
            content: [

                {
                    title: "Topics",
                    content: [
                        "App menu",
                        "Custom Labels",
                        "Custom settings",
                        "Custom Metadata Types",
                        "Static Resources",
                        "Density Settings",
                        "Global Search",
                        "Themes & Branding"
                    ]
                }
            ]
        },
        {
            title: "Users,Roles,Profiles,Permission Sets,Public Groups and Queues",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Creating Users",
                        "Mass Email Users",
                        "User License Overview",
                        "Understanding Record Ownership and Access",
                        "Roles and Role Hierarchy",
                        "Profiles",
                        "User Permissions",
                        "Permission Sets and Permission Set Groups",
                        "Public Groups",
                        "Queues"
                    ]
                }
            ]
        },
        {
            title: "Salesforce Objects Fields & Relationships",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Custom Objects Vs. Standard Objects",
                        "Creation of custom object",
                        "Different Data types",
                        "Standard Data types",
                        "Text, Email, Number, Currency, URL, Text Area, Rich Text Area, etc.,",
                        "Read only Data types",
                        "Auto Number, Formula and Rollup summary",
                        "Rollup summary Operations",
                        "*Sum(), Min(), Max() and Count",
                        "Relational Data types",
                        "Lookup Vs Master - Detail Relationship",
                        "Required, External Id, Default Value and Unique fields",
                        "Controlling field/Dependent field?",
                        "Making dependent pick list fields",
                        "Types of Tabs",
                        "Custom object Tabs",
                        "Web Tabs",
                        "Visual force Tabs",
                        "Lightning Page Tabs",
                        "Customize the page layouts",
                        "Defining Page layouts",
                        "Adding custom buttons and links",
                        "Defining required and read only fields",
                        "Adding related list",
                        "Adding Sections in Detail page",
                        "To create and maintain record types for your organization",
                        "Display different page layouts and picklist values based on record types",
                        "Uses of Record Types",
                        "Assigning Record types to the Profiles",
                        "Schema Builder",
                        "Creation of Application/Custom App"



                    ]
                }
            ]
        },
        {
            title: "Workflows,Approval,Process Builder",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Email Templates - Sending Single emails and Mass emails.",
                        "Types of Templates - Text, HTML (Using Letter Head), Custom (without using letter head), Visual force Email Templates",
                        "Merging fields in Email Templates",
                        "Email Delivery Settings",
                        "Defining Workflow",
                        "Workflow Actions",
                        "Email Alert",
                        "Task Creation",
                        "Field Updation",
                        "Outbound Message",
                        "Difference between Evaluation Criteria and Rule Criteria",
                        "Create Approval Process",
                        "Approval Actions",
                        "Difference between workflow rule and approval process",
                        "Creation of Automation Process"
                    ]
                }
            ]
        },
        {
            title: "Salesforce Flows",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Creation of Flows",
                        "Standard Validations",
                        "Custom Validations",
                        "Defining Salesforce Data Validation",
                        "Creating Custom Validation Rules"

                    ]
                }
            ]
        },
        {
            title: "Security Controls and identity Management",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Types Of Security",
                        "OWD Access Levels",
                        "Sharing Settings and Field Accessibility",
                        "Password Policies, Session Settings",
                        "Sharing Rules and Manual Sharing",
                        "Named Credentials and Remote Site Settings",
                        "Trusted URls for redirects",
                        "View Setup Audit Trail",
                        "Session Settings and Login IP",
                        "Certificate and Key Management",
                        "File Upload and Download Security",
                        "Health Check and User Session Information"

                    ]
                }
            ]
        },
        {
            title: "Salesforce Data Management",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is Data Backup?",
                        "Advantages for Data Backup",
                        "Odaseva (Data Backup Tool)",
                        "What is Meta data ?",
                        "Advantages for Metadata backup",
                        "Alation(Meta Data Backup Tool)",
                        "Import using Application Import Wizard",
                        "Import using Apex Data LoaderPlot",
                        "Import Wizard Vs Data Loader",
                        "Dataloader.ioBox Plots",
                        "Workbench"

                    ]
                }
            ]
        },
        {
            title: "Salesforce App Exchange",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction",
                        "Installing an App",
                        "Uninstalling an App",
                        "Unmanaged Package",
                        "Managed Package",
                        "Public group and Private group",
                        "Stream and Feed",
                        "Chatter Email settings",
                    ]

                }
            ]
        },
        {
            title: "Reports and Dashboards",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Creating Report Types for the Reports",
                        "Report Types, Reports & Dashboard Overview",
                        "Different Reports",
                        "Defining Custom Reports",
                        "Advanced Filters",
                        "Defining Dashboards"

                    ]

                }
            ]

        },
        {
            title: "Salesforce Monitoring and Deployment",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Imports",
                        "Outbound Messages",
                        "Time-Based Workflow",
                        "Case Escalations",
                        "API Usage Notifications",
                        "Mass Emails",
                        "Debug Logs",
                        "Scheduled Jobs",
                        "Email Log Files and Bulk Data Load Jobs",
                        "PMD (Scan)",
                        "Checkmarx (Scan)",
                        "Changesets",
                        "Continues integration(CI) and Continuous Deployment (CD)",
                        "Deploy the components using Azure Devops"
                    ]

                }
            ]
        },
        {
            title: "Introduction to salesforce Business Analyst",
            content: [

                {
                    title: "Topics",
                    content: [
                        "SDLC",
                        "Agile Methodology",
                        "Planning Scrum"

                    ]

                }
            ]
        },
        {
            title: "Customer Discovery",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Determining Business Strategies",
                        "Discovering Customer Leverage",
                        "Understanding responsibilities",
                        "Applying the implementation lifecycle",
                        "Analyzing Salesforce environment",
                        "Demonstrating Salesforce capabilities for business solutions"

                    ]

                }
            ]

        },
        {
            title: "Collaboration with Stakeholders",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Planning discovery activities",
                        "Identifying key stakeholders and developing relationships",
                        "Explaining current state analysis",
                        "Understanding Salesforce features and practices"

                    ]

                }
            ]
        },
        {
            title: "Business Process Mapping",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Demonstrating the scope of complex business process",
                        "Applying and understanding hierarchical processes",
                        "Analyzing business processes for future visualization"

                    ]

                }
            ]
        },
        {
            title: "Requirements",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Distinguishing between user stories and requirements",
                        "Verifying existing requirements to prioritize",
                        "Pushing document requirements to version-controlled repositories."

                    ]

                }
            ]
        },
        {
            title: "User Stories",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding components of user stories",
                        "Contrasting the difference between acceptance criteria",
                        "Pushing user stories to version-controlled repositories"

                    ]

                }
            ]
        },
        {
            title: "User Acceptance",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Defining and preparing UAT (User Acceptance Test Plan)",
                        "Guiding UAT to manage results",
                        "Make a go/no-go decision during technical issues"

                    ]

                }
            ]
        },
        {
            title: "Introduction to Apex",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is object oriented programming language?",
                        "What is Apex?",
                        "When Should I use Apex?",
                        "How does Apex Work?"

                    ]

                }
            ]
        },
        {
            title: "Apex Fundamentals",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Data Types and Variables (Primitive, Collections, Enums, Variables and Constants)",
                        "Expressions and Operators",
                        "Control Flow Statements(If-Else, Switch, Loops)",
                        "Classes, Objects, and Interface"
                    ]

                }
            ]
        },
        {
            title: "Working with Data in Apex",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Sobjects",
                        "Data Manipulation Language",
                        "SOQL Basics",
                        "SOQL Optional Clauses",
                        "Using SOQL and Collections.",
                        "Relationship Queries",
                        "Complex SOQL and Collections.",
                        "Data Retrieval - SOSL",
                        "Apex Classes,Objects",
                        "Basic Triggers",
                        "Context Variables",
                        "Trigger Frame Work"
                    ]

                }
            ]
        },
        {
            title: "Asynchronous Apex,Debugging,Testing,and Deploying Apex",
            content: [

                {
                    title: "Topics",
                    content: [
                        "IBatch Apex",
                        "Scheduled Apex",
                        "Queueable Apex",
                        "Future Methods"
                    ]

                }
            ]
        },
        {
            title: "Apex Security and Sharing",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Enforcing Sharing Rules",
                        "Enforcing Object and Field Permissions",
                        "Enforce User Mode for Database Operations",
                        "Filter SOQL Queries Using WITH SECURITY_ENFORCED",
                        "Apex Managed Sharing",
                        "Apex Test Classes",
                        "Best Apex Practices"
                    ]

                }
            ]
        },
        {
            title: "Introduction to Lightning Web Components",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Why Lightning Web Components and Why Salesforce Introduced LWC?",
                        "Advantages of using Lightning Web Components",
                        "Features of Lightning Web components",
                        "Set Up Your Development Environment (Install a Code Editor, Set",
                        "Up Linting, Set Up a Development Org, Install the Salesforce CLI,",
                        "Develop in Scratch Orgs, Develop in Non-Scratch Orgs)",
                        "LWC Supported JavaScript, Browsers, experiences and Tools."
                    ]

                }
            ]
        },
        {
            title: "Creation of Lighning Web Components",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Creation of LWC Bundle (folder, html, JavaScript, Configuration, CSS, SVG and Test files)",
                        "Fields, Properties (Reactive, Public, Tracked, Boolean, Getter and Private), and Attributes",
                        "Decorators and Directives",
                        "Component Lifecycle"

                    ]

                }
            ]
        },
        {
            title: "Work with Salesforce Data",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Lightning Data Service",
                        "Work with Records Using Base Components",
                        "Use the Wire Service to Get Data",
                        "Call Apex Methods",
                        "Call APIs from Apex",
                        "LWC Modules",
                        "Create and Dispatch Events",
                        "Handle Events",
                        "Configure Event Propagation",
                        "Communicate Across the DOM"
                    ]

                }
            ]
        },
        {
            title: "Debug Lightning Web Componenets",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Enable Debug Mode in Salesforce",
                        "Debug Using Chrome DevTools"

                    ]

                }
            ]
        },
        {
            title: "Test Lightning Web Components",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Install Jest",
                        "Run Jest Tests for Lightning Web Components",
                        "Debug Jest Tests for Lightning Web Components",
                        "Write Jest Tests for Lightning Web Components"

                    ]

                }
            ]
        },
    ],
    azureDevOps: [
        {
            title: "Applications & Web Technologies",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                        "Web Technologies used in Projects"

                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle(SDLC)",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process"

                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices"

                    ]
                }
            ]
        },
        {
            title: "Linux For DevOps & Cloud",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Linux OS",
                        "Linux Distributions and Architecture",
                        "Command Line Interface (CLI) & Filesystem",
                        "File Management and vi Editor",
                        "Archives and Package Management",
                        "System Installation and Package Managers",
                        "Users, Groups, and Permissions",
                        "Networking Basics: IP Address, Protocols, & Ports",
                        "Firewalls and Security Measures",
                        "Load Balancers"

                    ]
                }
            ]
        },
        {
            title: "Version Control - GIT,GITHUB",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Version Control System",
                        "Centralised vs Distributed Version Control System",
                        "Git & GitHub Introduction",
                        "Git Workflow",
                        "GitHub for Collaboration",
                        "Git Branching Model",
                        "Git Merging and Pull Requests",
                        "Git Rebase",
                        "Handling Detached Head and Undoing Changes",
                        "Advanced Git Features: Git Ignore, Tagging"

                    ]
                }
            ]
        },
        {
            title: "Containerisation - Docker",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Containerisation",
                        "Monolithic vs Microservices Architecture",
                        "Introduction to Virtualisation and Containerisation",
                        "Docker Architecture",
                        "Setting up Docker",
                        "Docker Registry, Images, and Containers",
                        "Running Docker Containers",
                        "Docker Volumes and Networks",
                        "Docker Logs and Tags",
                        "Dockerize Applications and Docker Compose"

                    ]
                }
            ]
        },
        {
            title: "Introduction to Azure DevOps",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is Azure DevOps?",
                        "Azure Boards",
                        "Azure Repos",
                        "Azure Pipelines",
                        "Creating Pipelines in Azure DevOps"

                    ]
                }
            ]
        },
        {
            title: "Agile Project Management with Azure Boards",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Agile Project Management Best Practices",
                        "Basic Concepts of Azure Boards",
                        "Connecting Boards to GitHub",
                        "Work Items and Sprints",
                        "Azure Boards Integrations"

                    ]
                }
            ]
        },
        {
            title: "Version Control with Azure Repos",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Azure Repos",
                        "Branches and Cloning in Azure Repos",
                        "Import Code from GitHub",
                        "Search Your Code in Repos",
                        "Azure Repos Integrations"

                    ]
                }
            ]
        },
        {
            title: "Continuous Integration/Deployment with Azure Pipelines",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deploying with Azure Pipelines",
                        "CI Triggers and YAML Basics",
                        "Setting Up CI Build",
                        "Adding Tests to the Pipeline",
                        "Agents and Tasks"

                    ]
                }
            ]
        },
        {
            title: "Azure Test Plans & Artifacts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Working with Packages in Azure Artifacts",
                        "Connection Feeds and Views in Artifacts",
                        "Connecting Azure Artifacts to Azure Pipelines",
                        "What are Azure Test Plans?",
                        "Testing Web Apps"

                    ]
                }
            ]
        },
        {
            title: "Introduction to Cloud Concepts and Azure",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Cloud Concepts"


                    ]
                }
            ]
        },
        {
            title: "Core Azure Services",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Azure Compute",
                        "Azure Storage",
                        "Azure Networking",
                        "Azure Database Services"


                    ]
                }
            ]
        },
        {
            title: "Azure Pricing,Support,and Governance",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Azure Pricing and Support",
                        "Azure Governance"


                    ]
                }
            ]
        },
        {
            title: "Managing Azure Resources",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Azure Portal and Azure CLI",
                        "Azure Management Tools"


                    ]
                }
            ]
        },
        {
            title: "Azure Application Services and Advanced Topics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "App Services",



                    ]
                }
            ]
        },
        {
            title: "Introduction to Kubernetes and Orchestration",
            content: [

                {
                    title: "Topics",
                    content: [
                        " Introduction to High Availability",
                        "Introduction to Container Orchestration",
                        "Container Orchestration Tools",
                        "Overview of Kubernetes",
                        "Kubernetes Architecture"


                    ]
                }
            ]
        },
        {
            title: "Core Components of Kubernetes",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Components of Kubernetes",
                        "Kubernetes Objects",
                        "Pods",
                        "Replica Sets",
                        "Deployments"



                    ]
                }
            ]
        },
        {
            title: "Kubernetes Services and Networking",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Services",
                        "ClusterIP",
                        "NodePort",
                        "Load Balancer",
                        "Ingress"


                    ]
                }
            ]
        },
        {
            title: "Configuration and Storage in Kubernetes",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Config Maps",
                        "Secrets",
                        "Persistent Volume (PV) and Persistent Volume Claim (PVC)",
                        "Storage Classes",
                        "StatefulSets"



                    ]
                }
            ]
        },
        {
            title: "Kubernetes in Production",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Production Clusters",
                        "Overview of AWS EKS",
                        "Setup EKS",
                        "Deploy Applications On EKS",
                        "Monitoring and Logging"



                    ]
                }
            ]
        },
        {
            title: "Introduction to Terraform",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Infrastructure as Code (IaC)",
                        "Overview of Terraform",
                        "Setup Terraform",
                        "Terraform Providers",
                        "Terraform Resources"



                    ]
                }
            ]
        },
        {
            title: "Terraform Core Concepts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Terraform State Management",
                        "Terraform Modules",
                        "Input Variables",
                        "Output Values",
                        "Terraform Workspaces"



                    ]
                }
            ]
        },
        {
            title: "Terraform Security and Efficiency",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Terraform and Security",
                        "Efficient Terraform Configurations",
                        "Terraform Plan and Apply Best Practices",
                        "Debugging Terraform Configurations",
                        "Terraform Versioning and Upgrades"



                    ]
                }
            ]
        },
        {
            title: "Terraform in Practice",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Terraform for Team Collaboration",
                        "Terraform in CI/CD Pipelines",
                        "Code Organization and Module Registry",
                        "Terraform Best Practices",
                        "Monitoring and Maintenance"



                    ]
                }
            ]
        },
        {
            title: "Teraform Collaboration and Version Control",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Version Control Systems in Terraform Workflows",
                        "Collaborative Workflows with Terraform",
                        "Terraform Code Reusability and Standards",
                        "Documenting Terraform Code",
                        "Introduction to Terraform Cloud for Teams"



                    ]
                }
            ]
        },
        {
            title: "Introduction to Python as a Scripting Language",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Python as a Scripting Language",
                        "Python Collections and Sequences",
                        "Working with Python Collections",
                        "Python Functional Programming",
                        "Python File Handling"



                    ]
                }
            ]
        },
        {
            title: "Advanced Python Concepts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Python Modules and Packages",
                        "Classes in Python",
                        "Object-Oriented Programming (OOP) in Python",
                        "Exception Handling",
                        "Python Decorators and Generators"



                    ]
                }
            ]
        },
        {
            title: "Python for Automation",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Automation through Scripting Languages",
                        "Automating File System Operations",
                        "Web Scraping with Python",
                        "Automating Network Tasks",
                        "Automating API Interactions"



                    ]
                }
            ]
        },
        {
            title: "Building and Deploying Python Applications",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Building Python Applications",
                        "Testing Python Applications",
                        "Python Application Deployment",
                        "CI/CD for Python Applications",
                        "Virtual Environments and Package Management"



                    ]
                }
            ]
        },
        {
            title: "Continuous Integration and Continuous Deployment(CI/CD) with Python",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Python in CI/CD Pipelines",
                        "Automating Builds and Tests with Python",
                        "Python for Deployment Automation",
                        "Monitoring and Logging with Python",
                        "Version Control Automation with Python"



                    ]
                }
            ]
        },
        {
            title: "Introduction to SRE Fundamentals",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to SRE",
                        "Introduction to Monitoring",
                        "Introduction to Observability",
                        "SRE Roles and Responsibilities",
                        "SRE Best Practices and Principles"



                    ]
                }
            ]
        },
        {
            title: "Monitoring with Prometheus",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Prometheus",
                        "Prometheus Architecture",
                        "Monitoring with Prometheus",
                        "Scraping Metrics with Prometheus",
                        "Prometheus YAML Configs and Node Exporter"



                    ]
                }
            ]
        },
        {
            title: "Observability with Grafana",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Visualization with Grafana",
                        "Installing Grafana on a Linux Server",
                        "Grafana User Interface Overview",
                        "Creating Grafana Dashboards",
                        "Grafana with Docker"



                    ]
                }
            ]
        },
        {
            title: "Advanced Monitoring and Obesrvability",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Integrating Prometheus and Grafana",
                        "Alerting with Prometheus",
                        "Log Management and Analysis",
                        "Tracing and Distributed Tracing",
                        "Cloud Monitoring Solutions"



                    ]
                }
            ]
        },
        {
            title: "SRE Tools and Automation",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Infrastructure as Code (IaC) for SRE",
                        "CI/CD Pipelines for Reliable Deployments",
                        "SRE and DevOps: Collaboration and Tools",
                        "Automation in Incident Management",
                        "Capacity Planning and Performance Tuning"



                    ]
                }
            ]
        },
    ],
    dataScience: [
        {
            title: " IT Basics for Data Science",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Information Technology in Data Science",
                        "Computer Science versus Information Technology: Orientation for Data Scientists",
                        "Fundamentals of Data Storage, Memory, and Processing Systems"
                    ]
                }
            ]
        },
        {
            title: "Introduce To Data Science",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Defining Data Science: Scope and Applications",
                        "Relationship between Data Science, Machine Learning, and Artificial Intelligence",
                        "Overview of the Data Science Lifecycle: From Problem Definition to Solution Deployment",
                    ]
                }
            ]
        },
        {
            title: "Basics of Database Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Database Fundamentals for Data Scientists",
                        "Comparative Analysis: SQL versus NoSQL",
                        "Introduction to Cloud Databases and Data Warehousing Solutions",
                    ]
                }
            ]
        },
        {
            title: "Data Structures and Algorithms",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Essential Data Structures for Data Science",
                        "Introduction to Algorithms in Data Science",
                        "Understanding Complexity and Big O Notation",
                    ]
                }
            ]
        },
        {
            title: "Working with Data Formats",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Data Types: Structured versus Unstructured Data",
                        "Working with Popular Data Formats: CSV, JSON, XML",
                        "Introduction to Data Extraction: Methods and Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Python Basics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Python Language Basics: Variables, Conditions, and Functions",
                        "Advanced Python Structures: Lists, Tuples, Dictionaries",
                        "Virtual Environments and Package Management in Python for Data Science",
                    ]
                }
            ]
        },
        {
            title: "Python Libraries for Data Manipulation",
            content: [
                {
                    title: "Topics",
                    content: [
                        "NumPy for Numerical Data Processing",
                        "Pandas for Data Wrangling and Analysis",
                        "Introduction to Data Cleaning Techniques with Python",
                    ]
                }
            ]
        },
        {
            title: "Python Libraries for Data Visualization",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Visualizing Data with Matplotlib and Seaborn",
                        "Advanced Data Visualization Techniques",
                        "Interactive Data Visualizations with Plotly and Dash",
                    ]
                }
            ]
        },
        {
            title: "Python for Data Acquisition",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Web Scraping with Python: Beautiful Soup and Scrapy",
                        "Utilizing APIs for Data Collection",
                        "Strategies for Working with Cloud Data in Python",
                    ]
                }
            ]
        },
        {
            title: "Object-Oriented Programming & Python Best PracticesDevelopment",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Object-Oriented Programming in Python",
                        "Writing Efficient and Readable Python Code",
                        "Error Handling, Debugging, and Unit Testing in Python",
                    ]
                }
            ]
        },
        {
            title: "Probability and Statistics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Foundations of Probability Theory",
                        "Descriptive Statistics and Inferential Statistics",
                        "Statistical Measures, Distributions, and Hypothesis Testing"
                    ]
                }
            ]
        },
        {
            title: "Advanced Statistical Methods",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Regression Techniques and Their Applications",
                        "Analysis of Variance (ANOVA) and Its Use Cases",
                        "Implementing Time Series Analysis in Data Science Projects",
                    ]
                }
            ]
        },
        {
            title: "Experimental Design and Analysis",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding Sampling Techniques and Methodologies",
                        "Principles of Experimental Design",
                        "Conducting A/B Testing and Result Interpretation",
                    ]
                }
            ]
        },
        {
            title: "Machine Learning Fundamentals",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Concepts in Supervised, Unsupervised, and Reinforcement Learning",
                        "Overfitting, Underfitting, and Model Validation Techniques",
                        "Cross-Validation and Hyperparameter Tuning",
                    ]
                }
            ]
        },
        {
            title: "Supervised Learning - Classification",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Overview of Classification Algorithms",
                        "Decision Trees, Random Forests, and Gradient Boosting Machines",
                        "Model Evaluation Metrics and Techniques",
                    ]
                }
            ]
        },
        {
            title: "Supervised Learning - Regression",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Linear Regression and Its Variants",
                        "Understanding Polynomial Regression and Regularization Techniques",
                        "Performance Evaluation in Regression Models",
                    ]
                }
            ]
        },
        {
            title: "Unsupervised Learning",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Clustering Techniques: K-Means, Hierarchical, and DBSCAN",
                        "Introduction to Principal Component Analysis (PCA)",
                        "Association Rule Mining: Concepts and Applications",
                    ]
                }
            ]
        },
        {
            title: "Advanced Machine Learning Techniques",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Ensemble Methods: Bagging, Boosting, and Stacking",
                        "Feature Engineering and Selection",
                        "Introduction to Advanced Algorithms: Neural Networks and SVMs",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Neural Networks",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basics and Anatomy of Neural Networks",
                        "Activation Functions: Types and Their Impact",
                        "The Training Process: Backpropagation and Learning Rates",
                    ]
                }
            ]
        },
        {
            title: "Deep Learning Frameworks and Tools",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Getting Started with TensorFlow: Installation and Basics",
                        "Building Models with Keras: A Gentle Introduction",
                        "PyTorch for Deep Learning: Key Features and Differences",
                    ]
                }
            ]
        },
        {
            title: "Convolutional Neural Networks (CNNs)",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Fundamental Concepts of CNNs and Their Architecture",
                        "Implementing a CNN for Image Recognition Tasks",
                        "Advanced Techniques: Transfer Learning and Fine-tuning",
                    ]
                }
            ]
        },
        {
            title: "Recurrent Neural Networks (RNNs) and LSTMs",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding RNNs: From Basics to LSTM Networks",
                        "Sequence Prediction and Text Generation with RNNs",
                        "Challenges and Solutions: Vanishing Gradients and Long-term Dependencies",
                    ]
                }
            ]
        },
        {
            title: "Advanced Topics in Deep Learning",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Exploring Generative Adversarial Networks (GANs)",
                        "Autoencoders for Feature Learning and Generation",
                        "Reinforcement Learning Basics: Building Intelligent Agents",
                    ]
                }
            ]
        },
        {
            title: "Foundations of Artificial Intelligence",
            content: [
                {
                    title: "Topics",
                    content: [
                        "The Landscape of AI: Defining AI and Its Domains",
                        "Rule-based AI vs. Machine Learning-driven AI",
                        "Evolution and Key Milestones in AI",
                    ]
                }
            ]
        },
        {
            title: "Natural Language Processing (NLP)",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Text Processing and Analysis",
                        "NLP Techniques: From Tokenization to Semantic Analysis",
                        "Leveraging NLP Libraries: NLTK, spaCy, and Beyond"
                    ]
                }
            ]
        },
        {
            title: "Computer Vision and AI",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Fundamentals of Computer Vision with AI",
                        "Implementing Object Detection and Recognition Systems",
                        "Advanced Applications: Facial Recognition and Autonomous Vehicles",
                    ]
                }
            ]
        },
        {
            title: "Ethics and Social Implications of AI",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Ethical AI Development: Challenges and Best Practices",
                        "Data Privacy and Security in AI Implementations",
                        "The Future of Work and Society with AI",
                    ]
                }
            ]
        },
        {
            title: "Emerging Trends in AI",
            content: [
                {
                    title: "Topics",
                    content: [
                        "General AI vs. Narrow AI: Understanding the Scope",
                        "AI in Robotics: Current State and Future Prospects",
                        "The Role of AI in Shaping Future Technologies",
                    ]
                }
            ]
        },
        {
            title: "Introduction to DevOps Practices for Data Science",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Overview and Importance of DevOps in Data Science",
                        "Implementing Continuous Integration (CI) and Continuous Deployment (CD) in ML",
                        "Containerization with Docker: Basics for Data Scientists",
                    ]
                }
            ]
        },
        {
            title: "Cloud Computing and Deployment",
            content: [
                {
                    // title: "Topics",
                    // content: [
                    //     "Linear Regression and Its Variants",
                    //     "Understanding Polynomial Regression and Regularization Techniques",
                    //     "Performance Evaluation in Regression Models",
                    // ]
                }
            ]
        },
        {
            title: "MLOps: Machine Learning Operations",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to MLOps Practices and Tools",
                        "Monitoring and Version Control for ML Projects",
                        "Managing the Lifecycle of ML Models in Production",
                    ]
                }
            ]
        },
        {
            title: "Scalable Machine Learning and Big Data",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Big Data Technologies for ML: Hadoop, Spark, and Beyond",
                        "Building Scalable ML Models on Big Data Platforms",
                        "Challenges and Solutions in Large-scale ML Deployment",
                    ]
                }
            ]
        },
        {
            title: "Ethical Considerations and Compliance",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding Data Governance and Compliance",
                        "Best Practices for Ethical AI and ML",
                        "Navigating Regulatory Requirements for Data Science Projects",
                    ]
                }
            ]
        },
    ],
    python: [
        {
            title: "Applications & Web Technologies",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        " DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Python Introduction and Setup",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Python's applicability across various domains",
                        "Installation, environment setup, and path configuration",
                        "Writing and executing the first Python script",
                    ]
                }
            ]
        },
        {
            title: "Python Fundamentals",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Keywords, Identifiers, and basic syntax",
                        "Variables, Data Types, and Operators",
                        "Introduction to Input/Output operations",
                    ]
                }
            ]
        },
        {
            title: "Control Structures and Functions",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Conditional Statements: If, Elif, Else",
                        "Loops: For, While, and control flow mechanisms",
                        "Understanding and defining Functions in Python",
                    ]
                }
            ]
        },
        {
            title: "Strings and Collections",
            content: [
                {
                    title: "Topics",
                    content: [
                        "String operations and manipulations",
                        "Lists and their operations",
                        "Introduction to Tuples and Sets",
                    ]
                }
            ]
        },
        {
            title: "Diving Deeper into Collections",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Detailed exploration of Dictionaries",
                        "Frozen Sets and their use-cases",
                        "Advanced list comprehensions",
                    ]
                }
            ]
        },
        {
            title: "Advanced Collections and Sequences",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced methods in Lists, Tuples, and Dictionaries",
                        "Sets, Frozen Sets, and operations",
                        "Comprehensive look into Python Collections",
                    ]
                }
            ]
        },
        {
            title: "Functional Programming in Python",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Exploring types of Functions and Arguments",
                        "Lambda functions and their applications",
                        "Map, Reduce, and Filter functions",
                    ]
                }
            ]
        },
        {
            title: "File Handling and Modules",
            content: [
                {
                    title: "Topics",
                    content: [
                        "File operations and handling different file formats",
                        "Working with Excel and CSV files in Python",
                        "Understanding and using Python Modules and Packages",
                    ]
                }
            ]
        },
        {
            title: "Object-Oriented Programming",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Deep dive into Classes, Objects, and Methods",
                        "Constructors, Destructors, and Types of Methods",
                        "Inheritance, Polymorphism, and Encapsulation",
                    ]
                }
            ]
        },
        {
            title: "Exception Handling and Regular Expressions",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Exception Handling: Try, Except, Finally",
                        "Creating and using Custom Exceptions",
                        "Utilizing Regular Expressions for pattern matching",
                    ]
                }
            ]
        },
        {
            title: "Getting Started with Django",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Django and its features",
                        "Setting up a Django project and understanding its structure",
                        "MVC Model, creating views, and URL mapping",
                    ]
                }
            ]
        },
        {
            title: "Django Core Concepts",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Database models and migrations",
                        "Admin interface and deploying Django applications",
                        "Forms and handling user inputs",
                    ]
                }
            ]
        },
        {
            title: "Advanced Django Features",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced URL routing and views",
                        "Class-based views and middleware",
                        "Working with static and media files",
                    ]
                }
            ]
        },
        {
            title: "Django REST Framework",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Building RESTful APIs with Django REST Framework",
                        "Serializers and request handling",
                        "Authentication and permissions in APIs",
                    ]
                }
            ]
        },
        {
            title: "Testing and Deployment",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Writing tests for Django applications",
                        "Deployment strategies and best practices",
                        "Configuring Django applications for production",
                    ]
                }
            ]
        },
        {
            title: "Data Science Foundations",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Data Science with Python",
                        "Data manipulation with Pandas",
                        "Data visualization with Matplotlib and Seaborn",
                    ]
                }
            ]
        },
        {
            title: "Advanced Data Manipulation",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced Pandas techniques and operations",
                        "Time Series data analysis with Pandas",
                        "Combining, merging, and reshaping data frames",
                    ]
                }
            ]
        },
        {
            title: "Data Visualization Deep Dive",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced visualization with Matplotlib",
                        "Interactive visualizations with Plotly",
                        "Geospatial data visualization",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Machine Learning",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basics of machine learning with Python",
                        "Using Scikit-learn for machine learning models",
                        "Model evaluation and validation techniques",
                    ]
                }
            ]
        },
        {
            title: "Advanced Topics in Data Science",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Neural Networks and Deep Learning",
                        "Working with text data and Natural Language Processing (NLP)",
                        "Introduction to Big Data technologies with Python",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Cloud Computing for Developers",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Basics",
                        "Cloud Service Providers Overview",
                        "Cloud-based Development Environments",
                        "Deploying Applications on the Cloud"
                    ]
                }
            ]
        },
        {
            title: "DevOps for Full Stack Developers",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding DevOps",
                        "Version Control with Git",
                        "Continuous Integration/Continuous Deployment (CI/CD)",
                        "Monitoring and Feedback",
                    ]
                }
            ]
        },
        {
            title: "Infrastructure and Configuration Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Containers and Docker",
                        "Managing Application Infrastructure",
                    ]
                }
            ]
        },
        {
            title: "Building and Deploying Scalable Web Applications",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Scalable Application Design",
                        "Cloud-native Services for Developers",
                        "Databases in the Cloud",
                        "Security Basics in Cloud and DevOps",

                    ]
                }
            ]
        },
        {
            title: "Project Collaboration and DevOps Practices",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Agile and Scrum Methodologies",
                        "Code Review and Collaboration Tools",
                        "Automation in Development",
                        "DevOps Culture and Best Practices"
                    ]
                }
            ]
        },
    ],
    aws: [
        {
            title: "Applications & Web Technologies",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        " DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Linux For DevOps & Cloud",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Linux OS",
                        "Linux Distributions and Architecture",
                        "Command Line Interface (CLI) & Filesystem",
                        "File Management and vi Editor",
                        "Archives and Package Management",
                        "System Installation and Package Managers",
                        "Users, Groups, and Permissions",
                        "Networking Basics: IP Address, Protocols, & Ports",
                        "Firewalls and Security Measures",
                        "Load Balancers",
                    ]
                }
            ]
        },
        {
            title: "Foundations of AWS",
            content: [
                {
                    title: "Topics",
                    content: [
                        "AWS Overview",
                        "Global Infrastructure",
                        "Responsibility Model",
                        "Account Basics",
                        "Console & CLI",
                        "Pricing Overview",
                        "Support Plans",
                        "Architecting Principles",
                        "Marketplace Intro",
                        "Security Practices",
                    ]
                }
            ]
        },
        {
            title: "Network Architecture and Connectivity",
            content: [
                {
                    title: "Topics",
                    content: [
                        "VPC Overview",
                        "VPC Configuration",
                        "ACLs & Security Groups",
                        "VPC Connectivity",
                        "Global Infrastructure",
                        "Scalable Architectures",
                        "Connectivity Options",
                        "CDN Implementation",
                        "Network Monitoring",
                        "Security Practices",
                    ]
                }
            ]
        },
        {
            title: " Compute and Storage Solutions",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Compute Services",
                        "Storage Services",
                        "Performance Optimization",
                        "Scalability & Elasticity",
                        "Data Lifecycle",
                        "Hybrid Computing",
                        "Distributed Computing",
                        "Caching Strategies",
                        "Cost Optimisation",
                        "Backup & Migration",
                    ]
                }
            ]
        },
        {
            title: " Database Solutions and Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding AWS database services (Aurora, DynamoDB, RDS)",
                        "Database engine selection and migration strategies",
                        "Database capacity planning, replication, and high availability",
                        "Implementing caching strategies using Amazon ElastiCache",
                        "Data access patterns and connection management",
                        "Database security best practices and compliance",
                        "Designing and optimizing database architectures",
                        "Implementing backup, recovery, and retention policies",
                        "Data encryption and key management using AWS KMS",
                        "Implementing backup, recovery, and retention policies",
                    ]
                }
            ]
        },
        {
            title: " Identity and Access Management (IAM)",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding IAM fundamentals and its role in AWS",
                        "Creating and managing IAM users, groups, and roles",
                        "Implementing IAM policies and permissions",
                        "Multi-factor authentication (MFA) setup and best practices",
                        "AWS IAM Identity Center (AWS Single Sign-On) overview",
                        "AWS IAM Identity Center (AWS Single Sign-On) overview",
                        "Role-based access control (RBAC) strategies in AWS",
                        "Implementing IAM best practices for security",
                        "Monitoring and auditing IAM activities",
                        "Designing a flexible IAM architecture for multiple AWS accounts",
                    ]
                }
            ]
        },
        {
            title: " Security and Compliance",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Designing secure access to AWS resources",
                        "Overview of AWS security best practices and the principle of least privilege",
                        "Securing AWS applications and workloads",
                        "Implementing data encryption at rest and in transit using AWS services",
                        "AWS compliance programs and aligning technologies to meet regulatory requirements",
                        "AWS security services overview (Amazon Cognito, Amazon GuardDuty, Amazon Macie)",
                        "Implementing security services for threat detection and monitoring",
                        "Data classification, retention, and governance best practices",
                        "Implementing access policies for encryption keys and sensitive data",
                        "AWS shared responsibility model and security responsibilities",

                    ]
                }
            ]
        },
        {
            title: "Workload and Application Security",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Securing application access and configuration on AWS",
                        "Implementing application-level security using AWS services",
                        "Understanding threat vectors and external security risks",
                        "Integrating AWS security services for enhanced protection (AWS Shield, AWS WAF)",
                        "Secure data storage and management best practices",
                        "Implementing security services like AWS Secrets Manager and AWS Identity Center",
                        "Designing and implementing secure APIs using Amazon API Gateway",
                        "Implementing authentication and authorization mechanisms",
                        "Data protection and compliance considerations",
                        "Application security testing and vulnerability management",

                    ]
                }
            ]
        }, {
            title: " High Availability and Resilience",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding high availability (HA) and fault tolerance in AWS",
                        "Designing scalable and resilient architectures",
                        "Implementing disaster recovery (DR) strategies and backup solutions",
                        "Overview of AWS managed services for HA and resilience",
                        "Load balancing concepts and strategies (Application Load Balancer, Network Load Balancer)",
                        "Failover strategies and multi-region architectures",
                        "Designing immutable infrastructure for enhanced reliability",
                        "Monitoring and visibility tools for HA and resilience (AWS X-Ray)",
                        "Strategies for mitigating single points of failure",
                        "Best practices for ensuring durability and availability of data"
                    ]
                }
            ]
        }, {
            title: "Cost Optimization and Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Overview of AWS cost management tools and services",
                        "Implementing cost allocation tags and multi-account billing",
                        "Understanding AWS purchasing options (Spot Instances, Reserved Instances, Savings Plans)",
                        "Cost-effective storage, compute, and database solutions",
                        "Implementing storage lifecycles, backups, and archival strategies",
                        "Optimising network and data transfer costs",
                        "Monitoring and analysing AWS cost and usage",
                        "Implementing cost-effective scaling and resource optimization",
                        "Selecting appropriate AWS services based on cost and requirements",
                        "Best practices for managing and controlling AWS costs"

                    ]
                }
            ]
        }, {
            title: "Advanced Architectural Design",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding microservices, serverless, and containerization in AWS",
                        "Designing event-driven architectures using AWS services",
                        "Implementing API creation and management with Amazon API Gateway",
                        "Best practices for workflow orchestration using AWS Step Functions",
                        "Designing and implementing multi-tier and multi-component architectures",
                        "Understanding queuing and messaging concepts (Amazon SQS, SNS)",
                        "Advanced networking concepts and edge services",
                        "Implementing purpose-built AWS services for specific workloads",
                        "Strategies for optimizing performance, security, and scalability",
                        "Designing high-performing and resilient architectures based on business requirements"

                    ]
                }
            ]
        }
    ],
    java: [
        {
            title: "Applications & Web Technologies",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        " DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Java",
            content: [
                {
                    title: "Topics",
                    content: [
                        "History and key features of Java",
                        "Java compared to other programming languages",
                        "Installation of Java and IDE setup",
                        "Writing and running the 'Hello World' program",
                        "Overview of JVM, JRE, and JDK",
                    ]
                }
            ]
        },
        {
            title: "Understanding Java's Core",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Variables, data types, and operators in Java",
                        "Control structures including loops and conditional statements",
                        "Basic Input/Output operations in Java",
                        "Java coding conventions and best practices",
                    ]
                }
            ]
        },
        {
            title: "Object-Oriented Programming Basics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to classes, objects, and methods",
                        "BUnderstanding inheritance and its types",
                        "Exploring polymorphism: method overloading and overriding",
                        "Abstract classes and interfaces",
                    ]
                }
            ]
        },
        {
            title: " Deep Dive into OOPS",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Encapsulation and its importance",
                        "Access modifiers: public, private, protected, and default",
                        "Non-access modifiers: static, final, abstract, synchronized",
                        "Constructors, destructors, and their roles in Java classes",
                        "Line Plots",
                    ]
                }
            ]
        },
        {
            title: "Java Data Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Working with arrays and array operations",
                        "String manipulation with Strings and StringBuilder",
                        "Basics of regular expressions in Java",
                        "Introduction to the Collections framework",
                    ]
                }
            ]
        },
        {
            title: "Exception Handling and Multithreading",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basics of exception handling using try-catch and finally",
                        "Creating custom exception classes",
                        "Fundamentals of multithreading and thread lifecycle",
                        "Synchronization and inter-thread communication techniques",
                    ]
                }
            ]
        },
        {
            title: "Advanced Data Handling",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Deep dive into the Collections framework",
                        "Using generics in Java",
                        "Introduction to Stream API and lambda expressions",
                        "File I/O operations and understanding the New I/O (NIO)",
                    ]
                }
            ]
        },
        {
            title: "Java Enterprise Essentials",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Database interactions using JDBC",
                        "Introduction to Servlets and JSP",
                        "Overview of Maven as a build and dependency management tool",
                        "Advanced enterprise patterns and practices",
                    ]
                }
            ]
        },
        {
            title: " Working with Hibernate and ORM",
            content: [
                {
                    title: "Topics",
                    content: [
                        "ORM concepts and integration with Hibernate",
                        "Configuration, session management, and advanced querying with Hibernate",
                        "Advanced mapping techniques in Hibernate",
                        "Performance tuning and caching mechanisms",
                    ]
                }
            ]
        }, {
            title: " Introduction to Spring Framework",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Core principles of IoC and DI in Spring",
                        "Building web applications with Spring MVC",
                        "Data access with Spring Data",
                        "Introduction to aspect-oriented programming with Spring AOP",
                    ]
                }
            ]
        },
        {
            title: "Spring Boot Fundamentals",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction and setup of Spring Boot",
                        "Configuration and customization in Spring Boot",
                        "Developing RESTful services with Spring Boot",
                        "Data access and integration with databases",
                    ]
                }
            ]
        }, {
            title: " Microservices with Spring Boot",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basics of microservices architecture",
                        "Creating and deploying microservices with Spring Boot",
                        "Implementing service discovery and using an API gateway",
                        "Ensuring fault tolerance and resilience in microservices",
                    ]
                }
            ]
        }, {
            title: " Advanced Spring Boot Features",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced REST API development techniques",
                        "Dockerization of Spring Boot applications",
                        "Overview of Spring Cloud services",
                        "Building reactive microservices with Spring Boot and WebFlux",
                    ]
                }
            ]
        }, {
            title: "Modern Development Practices",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Implementing CI/CD pipelines for Spring Boot applications",
                        "Security practices in Java and Spring applications",
                        "Monitoring and logging in microservices",
                        "Ensuring code quality with static analysis tools",
                    ]
                }
            ]
        }, {
            title: "Reactive Programming and Beyond",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Fundamentals of reactive programming with Spring WebFlux",
                        "Managing backpressure in reactive streams",
                        "Securing reactive applications",
                        "Best practices for developing reactive applications",
                    ]
                }
            ]
        },
        {
            title: " Introduction to Cloud Computing for Developers",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Basics",
                        "Cloud Service Providers Overview",
                        "Cloud-based Development Environments",
                        "Deploying Applications on the Cloud",
                    ]
                }
            ]
        }, {
            title: "DevOps for Full Stack Developers",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding DevOps",
                        "Version Control with Git",
                        "Continuous Integration/Continuous Deployment (CI/CD)",
                        "Monitoring and Feedback",
                    ]
                }
            ]
        },
        {
            title: "Infrastructure and Configuration Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Containers and Docker",
                        "Managing Application Infrastructure",
                    ]
                }
            ]
        }, {
            title: "Building and Deploying Scalable Web Applications",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Scalable Application Design",
                        "Cloud-native Services for Developers",
                        "Databases in the Cloud",
                        "Security Basics in Cloud and DevOps"
                    ]
                }
            ]
        }, {
            title: " Project Collaboration and DevOps Practices",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Agile and Scrum Methodologies",
                        "Code Review and Collaboration Tools",
                        "Automation in Development",
                        "DevOps Culture and Best Practices",
                    ]
                }
            ]
        }
    ],
    testing: [
        {
            title: "Applications & Web Technologies",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Fundamentals of Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Testing",
                        "The Necessity of Testing",
                        "Understanding Errors, Defects, and Failures",
                        "The Test Process",
                        "The Psychology of Testing",
                    ]
                }
            ]
        },
        {
            title: "Testing throughout that Software Development Life Cycle",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Software Development Lifecycle Models",
                        "Test Levels",
                        "Test Types",
                        "Test Types and Levels Analysis",
                    ]
                }
            ]
        },
        {
            title: "Static Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basics of Static Testing",
                        "Static vs. Dynamic Testing",
                        "Roles in the Review Process",
                    ]
                }
            ]
        },
        {
            title: "Test Techniques",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Categories of Test Techniques",
                        "Black-box Test Techniques",
                        "Experience-based Test Techniques",
                    ]
                }
            ]
        },
        {
            title: "Test Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Test Organization",
                        "Test Planning and Estimation",
                        "Test Monitoring and Control",
                        "Configuration Management",
                        "Risks and Testing"
                    ]
                }
            ]
        },
        {
            title: "Introduction to Java",
            content: [
                {
                    title: "Topics",
                    content: [
                        "History and key features of Java",
                        "Java compared to other programming languages",
                        "Installation of Java and IDE setup",
                        "Writing and running the 'Hello World' program",
                        "Overview of JVM, JRE, and JDK",
                    ]
                }
            ]
        },
        {
            title: "Understanding Java's Core",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Variables, data types, and operators in Java",
                        "Control structures including loops and conditional statements",
                        "Basic Input/Output operations in Java",
                        "Java coding conventions and best practices",
                    ]
                }
            ]
        },
        {
            title: "Object-Oriented Programming Basics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to classes, objects, and methods",
                        "BUnderstanding inheritance and its types",
                        "Exploring polymorphism: method overloading and overriding",
                        "Abstract classes and interfaces",
                    ]
                }
            ]
        },
        {
            title: " Deep Dive into OOPS",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Encapsulation and its importance",
                        "Access modifiers: public, private, protected, and default",
                        "Non-access modifiers: static, final, abstract, synchronized",
                        "Constructors, destructors, and their roles in Java classes",
                        "Line Plots",
                    ]
                }
            ]
        },
        {
            title: "Java Data Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Working with arrays and array operations",
                        "String manipulation with Strings and StringBuilder",
                        "Basics of regular expressions in Java",
                        "Introduction to the Collections framework",
                    ]
                }
            ]
        },
        {
            title: "Introduction to Automation Testing and Selenium",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Automation Testing Basics",
                        "Introduction to Selenium",
                        "Setting Up Selenium Environment",
                        "First Steps with Selenium WebDriver",
                    ]
                }
            ]
        },
        {
            title: "Web Elements and Data Handling in selenium",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Identifying Web Elements",
                        "Interacting with Web Elements",
                        "Handling Data Inputs and Validation",
                        "Advanced Element Interaction",
                    ]
                }
            ]
        },
        {
            title: "Advanced Selenium Techniques",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Synchronization in Selenium",
                        "Handling Multiple Windows and Frames",
                        "Executing JavaScript and Using Actions Class",
                        "Cross-browser Testing with Selenium Grid",
                    ]
                }
            ]
        },
        {
            title: "Frameworks and Best Practices in Selenium",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Selenium Test Frameworks Overview",
                        "Page Object Model (POM)",
                        "Data-driven Testing Framework",
                        "Keyword-driven and Hybrid Frameworks",
                    ]
                }
            ]
        },
        {
            title: "CI/CD Integration and Reporting in Selenium",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Integrating Selenium with CI/CD Tools",
                        "Test Reporting",
                        "Logging and Debugging",
                        "Selenium Project Best Practices",
                        "Future Trends and Selenium 4 Updates"
                    ]
                }
            ]
        },
        {
            title: "Introduction To API and API Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding APIs",
                        "Basics of API Testing",
                        "Setting Up the Environment for API Testing",
                        "HTTP Methods and Status Codes",
                    ]
                }
            ]
        },
        {
            title: "Advances API TestingConcepts",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Testing RESTful and SOAP APIs",
                        "Authentication and Authorization in APIs",
                        "Parameterization and Data-Driven Testing",
                        "Validation and Verification",
                    ]
                }
            ]
        },
        {
            title: "API Atuomation Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Automation in API Testing",
                        "Choosing the Right Tools for API Automation",
                        "Writing Automated API Tests",
                        "Integrating API Tests with Build Tools",
                    ]
                }
            ]
        },
        {
            title: "Performance and Security Testing for API's",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Performance Testing of APIs",
                        "Security Testing for APIs",
                        "Rate Limiting and Throttling Tests",
                        "Best Practices in Performance and Security Testing",
                    ]
                }
            ]
        },
        {
            title: "Documenting API'sand Best Practices in API Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "API Documentation Standards",
                        "Mocking and Virtualization of APIs",
                        "Continuous Testing and Monitoring",
                        "Advanced Topics and Trends in API Testing",
                        "Best Practices in API Testing"
                    ]
                }
            ]
        },
        {
            title: "Introduction Cloud computing for Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Basics",
                        "Cloud Service Providers Overview",
                        "Cloud-based Development Environments",
                        "Deploying Applications on the Cloud",
                    ]
                }
            ]
        },
        {
            title: "DevOps for Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding DevOps",
                        "Version Control with Git",
                        "Continuous Integration/Continuous Deployment (CI/CD)",
                        "Monitoring and Feedback",
                    ]
                }
            ]
        },
        {
            title: "InfraStructure Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Containers and Docker",
                        "Managing Application Infrastructure",
                    ]
                }
            ]
        },
        {
            title: "Building and Deploying Scalable Web Applications",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Scalable Application Design",
                        "Cloud-native Services for Developers",
                        "Databases in the Cloud",
                        "Security Basics in Cloud and DevOps",
                    ]
                }
            ]
        },
        {
            title: "Project Collaboration and DevOps practices ",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Agile and Scrum Methodologies",
                        "Code Review and Collaboration Tools",
                        "Automation in Development",
                        "DevOps Culture and Best Practices",
                    ]
                }
            ]
        },
    ],
    dataAnalysts: [
        {
            title: "Introduction And Basics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Installation",
                        "Python Org, Python 3",
                        "Variables",
                        "Print Function",
                        "Input From User",
                        "Data Types",
                        "Type Conversion",
                        "Why Python For Data Analysis And Data Science",
                        "How To Install Anaconda",
                        "Running Few Sample Programs Using Python"
                    ]
                }
            ]
        },
        {
            title: "Operators",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Arithmetic Operators",
                        "Relational Operators",
                        "Bitwise Operators",
                        "Logical Operators",
                        "Assignment Operators",
                        "Compound Operators",
                        "Membership Operators",
                        "Identity Operators",
                    ]
                }
            ]
        },
        {
            title: "Conditional Statements",
            content: [
                {
                    title: "Topics",
                    content: [
                        "If Else",
                        "If",
                        "Else",
                        "El If (Else If)",
                        "If Else Ternary Expression",
                    ]
                }
            ]
        },
        {
            title: "Conditional Statements",
            content: [
                {
                    title: "Topics",
                    content: [
                        "While Loop Logic Building",
                        "Series Based Questions",
                        "Break",
                        "Continue",
                        "Nested While Loops",
                        "Pattern-Based Questions",
                        "Pass",
                        "Loop Else"
                    ]
                }
            ]
        },
        {
            title: "Lists",
            content: [
                {
                    title: "Topics",
                    content: [
                        "List Basics",
                        "List Operations",
                        "List Comprehensions / Slicing",
                        "List Methods",
                    ]
                }
            ]
        },
        {
            title: "Strings",
            content: [
                {
                    title: "Topics",
                    content: [
                        "String Basics",
                        "String Literals",
                        "String Operations",
                        "String Comprehensions / Slicing",
                        "String Methods"
                    ]
                }
            ]
        },
        {
            title: "For Loops",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Range Function",
                        "For Loop",
                        "Nested For Loops",
                        "Pattern-Based Questions",
                        "Break",
                        "Continue",
                        "Pass",
                        "Loop Else"
                    ]
                }
            ]
        },
        {
            title: "Functions",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Definition",
                        "Call",
                        "Function Arguments",
                        "Default Arguments",
                        "Docstrings",
                        "Scope",
                        "Special Functions Lambda, Map, And Filter",
                        "Recursion",
                        "Functional Programming And Reference Functions"
                    ]
                }
            ]
        },
        {
            title: "Dictionary",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Dictionaries Basics",
                        "Operations",
                        "Comprehensions",
                        "Dictionaries Methods",
                    ]
                }
            ]
        },
        {
            title: "Tuple",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Tuples Basics",
                        "Tuples Comprehensions / Slicing",
                        "Tuple Functions",
                        "Tuple Methods",
                    ]
                }
            ]
        },
        {
            title: "Set",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Sets Basics",
                        "Sets Operations",
                        "Union",
                        "Intersection",
                        "Difference And Symmetric Difference"
                    ]
                }
            ]
        },
        {
            title: "Python Programming and Logic Building",
            content: [
                "Numpy",
                "Pandas",
                "Matplotlib",
                "Seaborn",
                "Sklearn",
                {
                    title: "Data Visualization Using Matplotlib And Seaborn",
                    content: [
                        "Introduction To Matplotlib",
                        "Basic Plotting",
                        "Properties Of Plotting",
                        "Sub Plots",
                        "Line Plots",
                        "Pie Chart",
                        "Bar Graph",
                        "Scatter Plot",
                        "Histograms",
                        "Box Plots",
                        "Violin Plots",
                        "Dist Plots",
                        "Dis Plots",
                        "Kde Plots"
                    ]
                }
            ]
        },
        {
            title: "Exploratory Data Analysis (Eda) With Dataset",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Uni-Variate Analysis",
                        "Bi-Variate Analysis",
                        " Multi-Variate Analysis",
                    ]
                }
            ]
        },
        {
            title: "R Programming",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Managing Data Frames With The Dplyr",
                        "Package",
                        "Control Structures",
                        "Functions",
                        "Lexical/Dynamic Scoping",
                        "Loop Functions",
                        "Debugging",
                        "Data Visualization In R",
                        "Storytelling With Data",
                        "Principle Tenets",
                        "Elements Of Data Visualization",
                        "Infographics Vs Data Visualization",
                        "Data Visualization & Graphical Functions In R",
                        "Plotting Graphs",
                        "Customizing Graphical Parameters To Improvise The Plots",
                        "Various Guis",
                        "Spatial Analysis",
                        "Other Visualization Concepts"
                    ]
                }
            ]
        },
        {
            title: "SQL Introduction",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What Is Datas",
                        "Types Of Data",
                        "What Is Database",
                        "Types Of Databases",
                        "What Is A Table"
                    ]
                }
            ]
        },
        {
            title: "Operations On Table",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Creation Of Table",
                        "Select Statement",
                    ]
                }
            ]
        },
        {
            title: "Data Modifications",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Insert Data Into Tables",
                        "Insert Statements & Insert Into Select Statements",
                        "Updating Existing Data",
                        "Deleting Data From Table",
                        "Drop",
                        "Drop",
                        "Truncating Tables",
                        "Alter Statements"
                    ]
                }
            ]
        },
        {
            title: "Constraints",
            content: [
                {
                    "title": "Topics",
                    "content": [
                        "Primary Key",
                        "Foreign Key",
                        "Unique Key",
                        "Not Null",
                        "Check",
                        "Default"
                    ]
                }
            ]
        },
        {
            title: "Indexes",
            content: [
                {
                    "title": "Topics",
                    "content": [
                        "Understanding Indexes And Their Importance",
                        "Creating And Dropping Indexes",
                        "Index (B Tree, Hash)"
                    ]
                }
            ]
        },
        {
            title: "Views",
            content: [
                {
                    "title": "Topics",
                    "content": [
                        "Creating Views",
                        "Modifying Views",
                        "Dropping Views",
                        "Updating Data Through View"
                    ]
                }
            ]
        },
        {
            title: "Data Retrieval",
            content: [
                {
                    "title": "Topics",
                    "content": [
                        "Retrieving Data From A Single Table",
                        "Where Clause",
                        "Group By Clause",
                        "Having Clause",
                        "Order By Clause",
                        "Limit Clause",
                        "Case Statement",
                        "Case Statement For Validating The Data Based On Condition"
                    ]
                }
            ]
        },
        {
            title: "Joins",
            content: [
                {
                    "title": "Topics",
                    "content": [
                        "Inner Join",
                        "Left Join",
                        "Right Join",
                        "Full Join",
                        "Cross Join",
                        "Self-Join"
                    ]
                }
            ]
        },
        {
            title: "Functions",
            content: [
                {
                    "title": "Topics",
                    "content": [
                        "Numerical Functions",
                        "Date Functions",
                        "String Functions",
                        "Aggregate Functions"
                    ]
                }
            ]
        },
        {
            title: "Set Operator",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Union",
                        "Intersect",
                        "Minus",
                        "Except",
                        "Union All"
                    ]
                }
            ]
        },
        {
            title: "Sub Queries",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Single Row",
                        "Multiple Row",
                        "Scalar Row",
                        "Correlated",
                        "Exist",
                        "Not Exist",
                        "From And Select",
                        "Where And From"
                    ]
                }
            ]
        },
        {
            title: "Stored Procedure",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Create",
                        "Dml",
                        "Tcl (Commit, Roll Back, Savepoints, Acid)",
                        "Cursor",
                        "Execution",
                        "Passing Parameters To Stored Procedures And Functions",
                        "Invoking Stored Procedures And Functions"
                    ]
                }
            ]
        },
        {
            title: "Advance SQL Topics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Windows Functions",
                        "Common Table Expression",
                        "Recursion",
                        "Pivot And Unpivot Operation",
                        "Dynamic SQL"
                    ]
                }
            ]
        },
        {
            title: "Power Bi Desktop User Interface",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Building Blocks Of Power Bi",
                        "Power Query",
                        "Data Processing, Data Types And Filters In Power Query",
                        "Inbuilt Column Transformation",
                        "In Built Row Transformations",
                        "Combine Queries (Merge Queries & Append Queries)",
                        "Merge Queries / Join Tables",
                        "Append Queries / Union All Tables",
                        "Query Options",
                        "How Tab Options",
                        "Transform Tab Options",
                        "Add Column Tab Options"
                    ]
                }
            ]
        },
        {
            title: "Power Pivot",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Power Bi Data Modeling – Model View (Previously Relationship View)",
                        "Enhancing The Data Model - Dax",
                        "Dax Function – Categories",
                        "Dax Text Functions",
                        "Dax Logical Functions",
                        "Dax Date & Time Functions",
                        "Dax Filter Function",
                        "Dax Math And Statistical Functions",
                        "Dax Time Intelligence Functions",
                        "Quick Measures"
                    ]
                }
            ]
        },
        {
            title: "Power View",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Report View",
                        "Visuals Interactions",
                        "Filters In Power View",
                        "Hierarchies And Drill-Down Reports"
                    ]
                }
            ]
        },
        {
            title: "Power Bi Visualizations",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Visuals For Filtering",
                        "Visualizing Categorical Data",
                        "Visualizing Trend Data",
                        "Visualizing KPI Data",
                        "Visualizing Tabular Data",
                        "Visualizing Geographical Data",
                        "Grouping, Binning & Sorting",
                        "Bookmarks, Selection Pane & Buttons"
                    ]
                }
            ]
        },
        {
            title: "Power Bi Services",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Adding Dataset To Power Bi Service And Creating Multiple New Reports",
                        "Dashboards Development"
                    ]
                }
            ]
        },
        {
            title: "Types Of Variables",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Nominal/Categorical",
                        "Ordinal",
                        "Interval/Ratio",
                        "Continuous, Time Series"
                    ]
                }
            ]
        },
        {
            title: "Central Tendency",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Mean",
                        "Median",
                        "Mode",
                        "Interquartile Mean"
                    ]
                }
            ]
        },
        {
            title: "Measures And Statistical Difference",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Variance",
                        "Correlation",
                        "Standard Error",
                        "IQR",
                        "Range",
                        "Mean Absolute Difference",
                        "Median Absolute Deviation",
                        "Skewness",
                        "Kurtosis",
                        "Correlation And Auto Correlation And Correlation Matrix",
                        "Correlation Ratio"
                    ]
                }
            ]
        },
        {
            title: "Hypothesis Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Chi-square",
                        "Z Test",
                        "T Test",
                        "ANOVA",
                        "P Value",
                        "Beta Test",
                        "F Score"
                    ]
                }
            ]
        },
        {
            title: "Data Preprocessing and Feature Engineering",
            content: [
                {
                    title: "Methods Of Imputation",
                    content: [
                        "Mean",
                        "Median",
                        "Mode",
                        "B-Fill",
                        "F-Fill",
                        "KNN Imputation",
                        "Random Forest Imputation",
                        "Regressor Based Imputation"
                    ]
                },
                {
                    title: "Encoding",
                    content: [
                        "Label Encoding",
                        "Dummy Encoding",
                        "Effect Encoding",
                        "Binary Encoding",
                        "Hash Encoding",
                        "Base N Encoding"
                    ]
                },
                {
                    title: "Feature Scaling",
                    content: [
                        "Standardization",
                        "Normalization"
                    ]
                },
                {
                    title: "Handling With Outliers",
                    content: [
                        "Z-Score",
                        "IQR",
                        "Percent"
                    ]
                }
            ]
        },
        {
            title: "Supervised Learning Regression",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Linear Regression",
                        "Polynomial Regression",
                        "Classification",
                        "Naïve Bayes",
                        "Logistic Regression",
                        "KNN",
                        "Decision Tree",
                        "SVM"
                    ]
                }
            ]
        },
        {
            title: "Unsupervised Learning",
            content: [
                {
                    title: "Topics",
                    content: [
                        "K-Means",
                        "C-Means",
                        "PCA"
                    ]
                }
            ]
        },
        {
            title: "Metrics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Classification Report",
                        "Confusion Matrix",
                        "Accuracy Score",
                        "Crosstab",
                        "F1-Score",
                        "Precision",
                        "Recall",
                        "ROC Curve",
                        "R Squared And Adjusted R Squared",
                        "RMSE, MSE",
                        "Evaluation Metrics"
                    ]
                }
            ]
        },
        {
            title: "Projects",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Live Kaggle Competition",
                        "Hands On Experience On Datasets",
                        "End To End Unique Projects"
                    ]
                }
            ]
        }
    ],
    businessAnalyst: [
        {
            title: "Applications & Web Technologies",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices",
                    ]
                }
            ]
        },
        {
            title: "Fundamentals of Bussiness Analyst",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Bussiness Analysis",
                        "Understanding Requirements",
                        "Business Analysis Tools and Techniques",
                    ]
                }
            ]
        },
        {
            title: "Bussiness Analysis Lifecycle and Stakeholder Collabaration",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Lifecycle Management",
                        "Working with Stakeholders",
                    ]
                }
            ]
        },
        {
            title: "Discovery and Analysis Techniques",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Conducting Discovery",
                        "Process Mapping and Improvement",
                    ]
                }
            ]
        },
        {
            title: "Solution Design and validation",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Designing Solutions",
                        "Requirements Validation",
                    ]
                }
            ]
        },
        {
            title: "Implementing and Quality Assurance",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Software Testing",
                        "Release Management and Post-Implementation",
                    ]
                }
            ]
        },
        {
            title: "SQL Fundamentals",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Introduction to SQL for data analysis",
                        "Basic SQL queries for data retrieval",
                        "Advanced data filtering, sorting, and aggregation in SQL",
                        "Complex queries with joins and subqueries",
                        "DML and DDL operations in SQL for data analysts"
                    ]
                }
            ]
        },
        {
            title: "Data Transformation Techniques",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Data cleaning and preparation strategies",
                        "Transforming and manipulating data with SQL",
                        "Introduction to Power BI for data analysis",
                        "Data transformation and cleansing with Power Query",
                        "Managing complex data structures for analysis"
                    ]
                }
            ]
        },
        {
            title: "Visualizing Data with power BI",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Fundamentals of data visualization",
                        "Creating and customizing visualizations in Power BI",
                        "Designing interactive dashboards",
                        "Dashboard design best practices",
                        "Publishing and sharing insights with Power BI"
                    ]
                }
            ]
        },
        {
            title: "Advanced Data Analysis with power BI",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Advanced DAX for complex calculations",
                        "Time series analysis in Power BI",
                        "Implementing data security in Power BI projects",
                        "Automating data refresh and management",
                        "Integration of Power BI with other services"
                    ]
                }
            ]
        },
        {
            title: "Collaborative Data Analysis",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Effective data storytelling with Power BI",
                        "Collaboration features in Power BI",
                        "Managing access and report sharing",
                        "Deploying Power BI Apps for organizations",
                        "Best practices for BI solutions maintenance"
                    ]
                }
            ]
        },
        {
            title: "Introduction To Software Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Fundamentals and importance of software testing",
                        "Types of testing: unit, integration, system, acceptance",
                        "Role of testing in the SDLC",
                        "Crafting effective test cases and plans",
                        "Introduction to automated testing"
                    ]
                }
            ]
        },
        {
            title: "Test Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Organizing testing phases and activities",
                        "Managing the defect lifecycle",
                        "Effective test case design strategies",
                        "Test prioritization and execution",
                        "Overview of test management tools"
                    ]
                }
            ]
        },
        {
            title: "Automated Testing Essentials",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basics of test automation and tool selection",
                        "Automating test cases for efficiency",
                        "Popular test automation frameworks",
                        "Maintaining automated test scripts",
                        "CI / CD integration with automated tests"
                    ]
                }
            ]
        },
        {
            title: "Performance and Security Testing",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to performance testing concepts",
                        "Load and stress testing techniques",
                        "Basics of security testing for applications",
                        "Common security vulnerabilities and tests",
                        "Tools for performance and security testing"
                    ]
                }
            ]
        },
        {
            title: "Advanced Testing Strategies ",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Implementing TDD and BDD in projects",
                        "Advanced automation: data - driven and keyword - driven testing",
                        "Strategies for mobile and cross- browser testing",
                        " Exploring non - functional testing: usability, accessibility",
                        "Upcoming trends in software testing"
                    ]
                }
            ]
        },
        {
            title: "Introduction to Cloud computing for Developers",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Basics",
                        "Cloud Service Providers Overview",
                        "Cloud-based Development Environments",
                        "Deploying Applications on the Cloud",
                    ]
                }
            ]
        },
        {
            title: "Devops for BA",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Understanding DevOps",
                        "Version Control with Git",
                        "Continuous Integration/Continuous Deployment (CI/CD)",
                        "Monitoring and Feedback",
                    ]
                }
            ]
        },
        {
            title: "Infrastructure Management",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Containers and Docker",
                        "Managing Application Infrastructure",
                    ]
                }
            ]
        },
        {
            title: "Building and Deploying Scalable Web Applications",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Scalable Application Design",
                        "Cloud-native Services for Developers",
                        "Databases in the Cloud",
                        "Security Basics in Cloud and DevOps",
                    ]
                }
            ]
        },
        {
            title: "Project Collaboration and DevOps Practices",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Agile and Scrum Methodologies",
                        "Code Review and Collaboration Tools",
                        "Automation in Development",
                        "DevOps Culture and Best Practices",
                    ]
                }
            ]
        },
    ],
    servicesNow: [
        {
            title: "Cloud Computing Basics",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Cloud Computing",
                        "What is Cloud Computing",
                        "Private and Public Cloud",
                        "What is the Difference Between SAAS and PAAS",
                        "What are the Benefits of Cloud Computing ?"
                    ]
                }
            ]
        },
        {
            title: "Introduction to ITIL",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is ITIL",
                        "Introduction to ITIL Foundation",
                        "ITIL Versions",
                        "Benefits of ITIL Standards",
                        "ITIL Approaches",
                        "Roles and Responsibility of IT Help Desk",
                        "Modules in ITIL"
                    ]
                }
            ]
        },
        {
            title: "ServiceNow Overview and Introduction",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to ITSM",
                        "Overview of ITSM",
                        "Introduction of Servicenow",
                        "What is Servicenow ?",
                        "Why and who can use Servicenow",
                        "History of Servicenow",
                        "Servicenow Features",
                        "Servicenow Objectives",
                        "Servicenow Lifecycle",
                        "Architecture of Servicenow",
                        "Servicenow Market Trends",
                        "Prerequisites for Servicenow",
                        "Servicenow Versions",
                    ]
                }
            ]
        },
        {
            title: "PDI Account Creation",
            content: [
                {
                    title: "Topics",
                    content: [
                        "New PDI Account Creation",
                        "Request for Developer Instance",
                        "How do we reset admin Password",
                        "Who will create Developer Instance",
                        "Use of stats.do",
                    ]
                }
            ]
        },
        {
            title: "User Interface",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is User Interface(UI) ?",
                        "Difference Between UI15 and UI16",
                        "User Profile",
                        "Basic Configuration",
                        "Purpose of Impersonate User",
                        "Use of Global Search",
                        "Toggle Connect Sidebar",
                        "Settings(Available Component)",
                    ]
                }
            ]
        },
        {
            title: "Forms",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is Form & Record ?",
                        " Form Headers and Fields",
                        "Form Design and Form Layout",
                        "Work with Form Sections",
                        "Field Properties",
                        "Working with Annotation",
                        "Creating Custom Fields from Design and Form Layout",
                        "Configure .Dot Walking",
                        "Form Customisation and Personalisation"
                    ]
                }
            ]
        },
        {
            title: "Customisations",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Client side vs UI Policies",
                        "UI Actions",
                        "Business Rules",
                        "Client Scripts",
                        "Data Policies",
                        "Script Includes",
                        "Update Sets, Plugins, & Recap"
                    ]
                }
            ]
        },
        {
            title: "Tables and Fields",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Tables",
                        "Fields & Field Types",
                        "Table Structure & Schema Maps",
                        "Table Maintenance & Custom Apps"
                    ]
                }
            ]
        },
        {
            title: "User Administration",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Users & Groups",
                        "New York Changes to Problem ACLs",
                        "Roles & ACLs",
                        "LDAP, SSO, & Impersonation"
                    ]
                }
            ]
        },
        {
            title: "Core Applications",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Incident Management & SLAs",
                        "Problem Management",
                        "Change Management",
                        "Configuration Management",
                        "Service Catalog",
                        "Knowledge Management",
                        "Service Portal",
                        "Connect & Visual Task Boards"
                    ]
                }
            ]
        },
        {
            title: "System Administration",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Basic Configuration & System Properties",
                        "Dashboards & Self - Service",
                        "ServiceNow Curriculum 5",
                        "Mobile, Upgrades, & HI",
                        "Diagnostics & Troubleshooting",
                        "Events & Notifications",
                        "Workflows",
                        "Import Sets",
                        "Reporting"
                    ]
                }
            ]
        },
        {
            title: "Building a Custom App",
            content: [
                {
                    title: "Topics",
                    content: [
                        "App Structure",
                        "Creating the App",
                        "Finishing the Games Table",
                        "Creating the Reviews Table",
                        "Loading Data",
                        "Administration",
                        "Customisations",
                        "Reporting & Demo",
                        "Final Words"
                    ]
                }
            ]
        },
        {
            title: "Javascript Fundamentals",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Java Script Introduction",
                        "History of Java Script",
                        "FClient Side Java Script and Server Side Java Script",
                        "Java Script Templates",
                        "Use of template. Print",
                        "Types of Variable",
                        "Working with String and String Concatenation",
                        "Working with Arrays",
                        "Java Script Ari",
                        "Java Script Assignment Operators",
                        "Variables in Java Script",
                        "Working with Mathematical Operations",
                        "Work with Conditions(if, else if and else)",
                        "Working with Switch Loop",
                        "Working with Functions",
                        "Java Script Objects",
                        "Working with Random and Maths",
                        "Java Script Error Handling"
                    ]
                }
            ]
        },
        {
            title: "Glide API's",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Glide API's",
                        "Overview of Glide API's",
                        "Client Side Glide API's and Server Side Glide API's",
                        "Working with Important Glide API's",
                        "Glide Record",
                        "Working with Glide Record Methods",
                        "Glide Form",
                        "Working with Glide Form Methods",
                        "Glide User",
                        "Working with Glide User Methods",
                        "Glide Session",
                        "Working with Glide Session Methods",
                        "Glide Date",
                        "Working with Glide Date Methods",
                        "Glide Data and Time",
                        "Working with Glide Date and Time Methods",
                        "Glide List",
                        "Working with Glide List Methods"
                    ]
                }
            ]
        },
        {
            title: "Client Scripts",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Client Scripts",
                        "Purpose of Client Scripts and Where These are Run",
                        "Types of Client Scripts",
                        "Elaborate onLoad, onChange, onSubmit and onCellEdit Client scripts",
                        "Create New Client Script",
                        "Working with More Client Script Examples",
                        "Catalog Client Script",
                        "Difference Between Client Scripts and Catalog Client Scripts",
                    ]
                }
            ]
        },
        {
            title: "UI Actions",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to UI Actions",
                        "Importance of UI Action",
                        "Working with Existing UI Actions",
                        "Create new UI Action",
                        "Creating UI Actions into Different Places",
                        "Working with Client Side UI Actions",
                        "Importance of gsftsubmit in UI Action",
                        "Working with more Examples",
                    ]
                }
            ]
        },
        {
            title: "Business rules",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Business Rules",
                        "Importance of Business Rules",
                        "Working with Display and Query Business Rule",
                        "Working with Async and Sync business rule",
                        "Business Rule Actions",
                        "Prevent recursive business rules",
                        "Working with existing business rules in PDI",
                        "Create new business rule",
                        "Global variables in Business Rule",
                        "Working with more example",
                    ]
                }
            ]
        },
        {
            title: "UI Script",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to UI Scripts",
                        "Working with Global UI Scripts",
                        "Create new UI Script",
                        "Run UI Scripts",
                        "UI Scripts on Client Side",
                    ]
                }
            ]
        },
        {
            title: "Scheduled Jobs",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Scheduled jobs",
                        "Schedule jobs States",
                        "Create new Schedule job",
                        "Run Schedule jobs",
                        "View Schedule Item"
                    ]
                }
            ]
        },
        {
            title: "Script Include",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Script Include",
                        "Use of Script Include",
                        "Types of Script Include",
                        "Server Side Script Include",
                        "Client Side Script Include",
                        "Difference Between Global Business Rule and Script Include",
                        "Create New Script Include",
                        "Calling Script Include into Business Rules",
                        "Calling Script Include into Client Side"
                    ]
                }
            ]
        },
        {
            title: "Script Include with Glide Ajax",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Glide Ajax",
                        "Types of Glide Ajax",
                        "Importance of Glide Ajax",
                        "How to Call Script Include with Glide Ajax?"
                    ]
                }
            ]
        },
        {
            title: "Inbound Email Actions",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Inbound Email Action",
                        "Overview of Inbound Email Action",
                        "Types of Incoming Email",
                        "Create Inbound Email Action",
                        "Importance of New, Reply and Forward"
                    ]
                }
            ]
        },
        {
            title: "Fix Scripts",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Fix Script",
                        "Create Fix Script",
                        "Run Fix Script",
                        "Testing Fix Scripts",
                    ]
                }
            ]
        },
        {
            title: "Transform Event Scripts",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Transform Event Scripts",
                        "Types of Event Scripts",
                        "Working with All Types of Event Scripts",
                        "Test Coalescing and The Transform Script",
                    ]
                }
            ]
        },
        {
            title: "Implementation",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Implementation",
                        "Working with Scope or Custom Applications",
                        "Using All ServiceNow Components to Implement Custom Application",
                    ]
                }
            ]
        },
        {
            title: "Integration(Optional)",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Out of the Box REST API",
                    ]
                }
            ]
        },
    ],
    Snowflake: [
        {
            title: "Applications & Web Technologies",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                        "Web Technologies used in Projects"
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices"
                    ]
                }
            ]
        },
        {
            title: "Linux For Cloud",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction to Linux OS",
                        "Linux Distributions and Architecture",
                        "Command Line Interface (CLI) & Filesystem",
                        "File Management and vi Editor",
                        "Archives and Package Management",
                        "System Installation and Package Managers",
                        "Users, Groups, and Permissions",
                        "Networking Basics: IP Address, Protocols, & Ports",
                        "Firewalls and Security Measures",
                        "Load Balancers"


                    ]
                }
            ]
        },
        {
            title: "Introduction to Snowflake and Cloud Data Warehousing",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Database Fundamentals with Focus on OLAP and Data Warehouses",
                        "Cloud Computing Basics - IaaS, PaaS, and SaaS Explained",
                        "Introduction to the Snowflake Cloud Data Warehouse"


                    ]
                }
            ]
        },
        {
            title: "Getting Started with Snowflake",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Setting Up a Snowflake Account",
                        "Understanding Snowflake Editions and Cloud Platforms",
                        "Navigating the Snowflake UI and Web Interface"



                    ]
                }
            ]
        },
        {
            title: "Snoeflake Architecture and Data Warehouse Concepts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Snowflake's Architecture: Shared Disk and Shared Nothing Models",
                        "Introduction to Virtual Warehouses",
                        "Snowflake Storage and Computing Resources"




                    ]
                }
            ]
        },
        {
            title: "Working with Snowflake Database and Tables",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Creating and Managing Snowflake Databases",
                        "Understanding Snowflake Tables: Permanent, Transient, and Temp",
                        "Introduction to Table Cloning and Time Travel Features"

                    ]
                }
            ]
        },
        {
            title: "Data Management and Security in Snowflake",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding Schemas and Session Context",
                        "Implementing Constraints and Understanding Snowflake Data Types",
                        "Basic Security Management in Snowflake"



                    ]
                }
            ]
        },
        {
            title: "Advanced Data Handiling and Optimization",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deep Dive into Snowflake Cloning and Zero Copy Cloning",
                        "Advanced Query Performance Tuning Techniques",
                        "Optimizing Data Storage and Retrieval with Partitions and Clustering"

                    ]
                }
            ]
        },
        {
            title: "Snowflake's Data Import and Export Tools",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Leveraging Snowflake Stages for Data Import and Export",
                        "Using SnowPipes for Efficient Data Loading",
                        "Working with External Stages and Integrating with Cloud Storage Solutions"

                    ]
                }
            ]
        },
        {
            title: "Advanced Snowflake Features",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Utilizing Snowflake Streams and Tasks for Automation and Real-time Processing",
                        "Snowflake Transactions and Data Integrity",
                        "Creating and Managing Views and Stored Procedures in Snowflake"


                    ]
                }
            ]
        },
        {
            title: "Exploring SnowPark for Advanced Analytics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to SnowPark and its Capabilities",
                        "Utilizing SnowPark for Developing Advanced Data Pipelines",
                        "Integration of SnowPark with Data Science and Machine Learning Workflows"

                    ]
                }
            ]
        },
        {
            title: "Administration,Security,and Integration",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Advanced Security Management and Role-Based Access Control (RBAC)",
                        "Using Snowflake with BI Tools: A focus on Power BI Integration",
                        "Preparing for Snowflake Certifications: Tips and Resources"


                    ]
                }
            ]
        },
    ],
    sre: [
        {
            title: "Introduction to SRE",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to SRE",
                        "Identify its roles and responsibilities",
                        "Scope of Work of SRE",
                        "Core Skills of SRE",
                        "How SRE benefits an organization",
                        "Learn about the SRE team"

                    ]
                }
            ]
        },
        {
            title: "SRE Roles and Responsibilities",
            content: [

                {
                    title: "Topics",
                    content: [
                        "SRE Operations",
                        "SRE Depends Teams",
                        "On-call incident response",
                        "Post-mortem",
                        "Reliable product launches",
                        "Identify toil in an enterprise",
                        "Understand how toil is created",
                        "Use SRE best practices to reduce toil",
                        "Cost & risk",
                        "Risk & SRE",
                        "SRE Costsf"

                    ]
                }
            ]
        },
        {
            title: "Planning High Availability",
            content: [

                {
                    title: "Topics",
                    content: [
                        "SLO's and SLI's",
                        "IT Assets",
                        "High Availability",
                        "Disaster Recovery",
                        "Making Infrastructure Highly Available",
                        "Creating and deploying HA and DR infrastructure using Terraform",
                        "High Availability and DR of Databases",
                        "Deploying High Availability Infrastructure"

                    ]
                }
            ]
        },
        {
            title: "Planning Incident Response",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Define Monitoring Objectives",
                        "Set Up Prometheus",
                        "Create Alerting Rules for black box",
                        "Creating a DR Plan",
                        "When To Use Availability and DR"


                    ]
                }
            ]
        },
        {
            title: "Terraform-HA",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Terraform Introduction",
                        "Terraform Resources",
                        "Deploying Assets via Terraform",
                        "Deploy Applications On AWS - Terraform",
                        "Using Terraform to Deploy Assets in Another Region",
                        "When To Use And Not to Use Terraform",
                        "Creating and deploying HA and DR infrastructure using Terraform"


                    ]
                }
            ]
        },
        {
            title: "High Availability and DR of Databases",
            content: [

                {
                    title: "Topics",
                    content: [
                        "High Availability and DR of Databases?",
                        "Recovery Time Objective (RTO)",
                        "Recovery Point Objective (RPO)",
                        "Implement Geo-Replication for MySSQL",
                        "Database replication",
                        "Implement Geo-Replication for MySQL Instances Demo",
                        "Automated Backup for MySQL Demo",
                        "Database Failover Demo",
                        "When To Use High Availability and DR of Databases"


                    ]
                }
            ]
        },
        {
            title: "Self Healing & Scaling Architecture",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Self-Healing Architectures",
                        "Identifying Single Points of Failure",
                        "3-Tier Architecture",
                        "Exercise: Three-tier Architecture",
                        "Introduction to Scaling",
                        "Self-healing Automation Strategies",
                        "Automating Scaling",
                        "Automating Fault Tolerance",
                        "Automating High Availability"


                    ]
                }
            ]
        },
        {
            title: "Deployment Strategies",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Deployment Strategies",
                        "Basic Deployments - VM",
                        "Container Deployments - Docker",
                        "Highly Available Deployments - Kubernetes",
                        "Local Kubernetes Cluster",
                        "Create a Local Kubernetes Cluster - Minikube",
                        "Rolling Deployments",
                        "Canary Deployments",
                        "Blue-green Deployments",
                        "AWS EKS Cluster",
                        "Exercise: Blue-green Deployment"

                    ]
                }
            ]
        },
        {
            title: "Monitoring with Prometheus",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Monitoring - Prometheus - Grafana",
                        "PromQL, TSDB (Time Series Database) and Data Collection.",
                        "Installation of Prometheus , Grafana, node exporter, alert manager and Blackbox.",
                        "Infrastructure Metrics and Application Metrics"


                    ]
                }
            ]
        },
        {
            title: "Visualisation with Grafana",
            content: [

                {
                    title: "Topics",
                    content: [
                        ". Architecture of Prometheus",
                        "Monitoring with Prometheus",
                        "Scraping of Infra and App Metrics",
                        "Node Monitoring with Node Exporter",
                        "Website Monitoring with Blackbox",
                        "Creating Alerts with Alert Manager",
                        "Creating Dashboards with Grafana",
                        "Slack Integrations with Incoming Webhooks"


                    ]
                }
            ]
        },
    ],
    sql: [
        {
            title: "Applications & Web Technologies",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                        "Web Technologies used in Projects"

                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle(SDLC)",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process"

                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices"

                    ]
                }
            ]
        },
        {
            title: "Linux For Cloud",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Linux OS",
                        "Linux Distributions and Architecture",
                        "Command Line Interface (CLI) & Filesystem",
                        "File Management and vi Editor",
                        "Archives and Package Management",
                        "System Installation and Package Managers",
                        "Users, Groups, and Permissions",
                        "Networking Basics: IP Address, Protocols, & Ports",
                        "Firewalls and Security Measures",
                        "Load Balancers"


                    ]
                }
            ]
        },
        {
            title: "Introduction to SQL Server",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Data and Databases Basics",
                        "Introduction to RDBMS",
                        "Understanding OLTP, DWH, and OLAP",
                        "Microsoft SQL Server Overview",
                        "SQL and T-SQL Overview",
                        "Key Roles of SQL DBA",
                        "Routine, Emergency, and Maintenance DBA Activities",
                        "Introduction to SQL Server Components"


                    ]
                }
            ]
        },
        {
            title: "Installing SQL Server",
            content: [

                {
                    title: "Topics",
                    content: [
                        "System Requirements and Prerequisites for Installation",
                        "SQL Server Installation Process Overview",
                        "Understanding SQL Server Instances",
                        "Configuring Authentication Modes",
                        "Post-Installation Steps"


                    ]
                }
            ]
        },
        {
            title: "SQL Server Management Studio (SSMS)",
            content: [

                {
                    title: "Topics",
                    content: [
                        "SSMS Overview and Interface",
                        "Connecting to Database Instances",
                        "Exploring System Databases",
                        "Basic Database Operations in SSMS",
                        "Data Insertion and Basic Retrieval Techniques in SSMS"


                    ]
                }
            ]
        },
        {
            title: "Deep Dive into SQL Basics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "DDL, DML, DCL, and TCL Commands Overview",
                        "Advanced Data Insertion Techniques",
                        "SELECT Statement Enhancements",
                        "Data Selection with WHERE Clause",
                        "Batch Operations and Transactions Basics"

                    ]
                }
            ]
        },
        {
            title: "Advanced Data Management",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding Schemas and Their Importance",
                        "Temporary Tables and Their Use Cases",
                        "Implementing Data Integrity with Constraints",
                        "Basics of Indexing for Performance"


                    ]
                }
            ]
        },
        {
            title: "Complex Queries and Joins",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deep Dive into JOIN Types",
                        "Practical Scenarios for Using JOINS",
                        "Optimization Techniques for JOINS",
                        "Using Advanced SQL Queries for Data Analysis"


                    ]
                }
            ]
        },
        {
            title: "Automating and Enhancing SQL Operations",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Triggers and Their Use Cases",
                        "Managing Database Transactions",
                        "Utilizing GROUP BY for Advanced Data Aggregation",
                        "Configuring Linked Servers for Remote Data Access"


                    ]
                }
            ]
        },
        {
            title: "Understanding SQL Server Architecture and Memory",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deep Dive into SQL Server Architecture",
                        "Data and Log File Management",
                        "SQL Server Memory Management Internals",
                        "Utilizing Filegroups for Optimized Data Storage"


                    ]
                }
            ]
        },
        {
            title: "Backup and Disaster Recovery",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Planning and Executing Database Backups",
                        "Backup Types and Their Appropriate Use Cases",
                        "Database Restoration Practices",
                        "Disaster Recovery Strategies"


                    ]
                }
            ]
        },
        {
            title: "SQL Server Maintenance and Security",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Setting Up SQL Server Agent for Job Management",
                        "Creating and Managing Maintenance Plans",
                        "SQL Server Security Best Practices",
                        "Implementing Encryption and Auditing"


                    ]
                }
            ]
        },
        {
            title: "SQL Fundamentals",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Databases and SQL: Understanding relational databases and the role of SQL.",
                        "SQL Syntax Overview: Keywords, statements, and clauses",
                        "Basic SQL Commands: SELECT, FROM, WHERE, and ORDER BY.",
                        "Filtering Data: Using conditions to retrieve specific data (AND, OR, NOT)."


                    ]
                }
            ]
        },
        {
            title: "Working with Multiple Tables",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding Table Relationships: Primary keys, foreign keys, and the importance of relationships in databases.",
                        "Join Operations: INNER JOIN, LEFT JOIN, RIGHT JOIN, and FULL JOIN.",
                        "Subqueries and Nested Queries: Using subqueries in the SELECT, FROM, and WHERE clauses.",
                        "Aggregating Data: Using GROUP BY and aggregate functions (COUNT, SUM, AVG, MIN, MAX)."



                    ]
                }
            ]
        },
        {
            title: "Advanced Data Manipulation",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Data Manipulation Commands: INSERT, UPDATE, DELETE.",
                        "Managing Tables: Creating and altering tables (CREATE TABLE, ALTER TABLE, DROP TABLE)",
                        "Advanced Filtering Techniques: Using LIKE, IN, BETWEEN, and wildcard characters.",
                        "Working with Dates and Times: Understanding and manipulating date and time data."



                    ]
                }
            ]
        },
        {
            title: "Complex Queries and ",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Advanced SQL Functions: String functions, mathematical functions, and date functions.",
                        "Window Functions: Overviews of ROW_NUMBER, RANK, DENSE_RANK, LEAD, LAG, and their applications.",
                        "Query Performance Optimization: Indexes, query planning, and execution paths.",
                        "Common Table Expressions (CTEs): Writing cleaner and more readable queries with WITH clause."




                    ]
                }
            ]
        },
        {
            title: "SQL for Data Analysis Specifics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Analytical SQL for Reporting: Building complex queries to answer analytical questions.",
                        "Pivoting Data: Transforming rows to columns (PIVOT) and columns to rows (UNPIVOT).",
                        "Data Warehousing Concepts: Introduction to data warehousing practices and how they apply to SQL querying",
                        "Integrating SQL with Data Analysis Tools: Connecting SQL databases with tools like Excel, Power BI, and Python for deeper data analysis."



                    ]
                }
            ]
        },
        {
            title: "Introduction to Azure SQL",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Azure SQL - Logical Servers and Managed Instances",
                        "Setting up SSMS and Azure Data Studio for Azure SQL"




                    ]
                }
            ]
        },
        {
            title: "Azure SQL Configuration and Scaling",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Azure SQL Server (Logical Server) Setup",
                        "Configuring Azure SQL Server Firewall for Security",
                        "Azure SQL Database Pricing Tiers and Scalability",
                        "Accessing Azure SQL Databases from SSMS",
                        "Dynamic Scalability with Elastic Pools and DTU",
                        "Implementing General Purpose and Business Critical Plans"




                    ]
                }
            ]
        },
        {
            title: "Migrations and Advanced Data Services",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Strategies for Data Migration to Azure SQL",
                        "Using Azure Storage for Database Migration",
                        "Understanding the Data Transaction Unit (DTU) and vCore Models",
                        "Implementing Schema Generation and Compatibility Checks",
                        "Utilizing Elastic Queries for Cross-Database Operations"




                    ]
                }
            ]
        },
        {
            title: "Database Management and Performance Tuning",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Azure SQL Database Level Tuning and Automated Tuning Options",
                        "Monitoring and Optimizing Query Performance",
                        "Index Management and Performance Insights in Azure SQL",
                        "Implementing Azure Search for Enhanced Data Retrieval"



                    ]
                }
            ]
        },
        {
            title: "High Availability,Disaster Recovery,and Security",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Configuring Geo-Replication and Failover Groups for HA/DR",
                        "Azure SQL Backup Options and Long-Term Retention Policies",
                        "Database Recovery and Geo-Redundant Backups",
                        "Security Management in Azure SQL - Firewalls, RLS, and Permissions",
                        "Understanding and Implementing Row-Level Security (RLS)",
                        "Basics of Transparent Data Encryption and Dynamic Data Masking",
                        "PowerShell and Azure CLI for Database Administration",
                        "Setting up Windows Clusters and Always On Availability Groups with Azure VMs",
                        "DP-300 Certification Guidance, including study materials and sample questions"



                    ]
                }
            ]
        },
        {
            title: "Introduction to Azure Database for PostgreSQL",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding the benefits and considerations of using cloud services.",
                        "Exploring Infrastructure-as-a-Service (IaaS), Platform-as-a-Service (PaaS), Software-as-a-Service (SaaS).",
                        "Differentiating between Public Cloud, Private Cloud, and Hybrid Cloud models."





                    ]
                }
            ]
        },
        {
            title: "Core Azure Services",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Azure Compute",
                        "Azure Storage",
                        "Azure Networking",
                        "Azure Database Services",





                    ]
                }
            ]
        },
        {
            title: "Azure Pricing,Support,and Governance",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Azure Pricing and Support",
                        "Azure Governance"





                    ]
                }
            ]
        },
        {
            title: "Managing Azure Resources",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Azure Portal and Azure CLI",
                        "Azure Management Tools"



                    ]
                }
            ]
        },
        {
            title: "Azure Application Services and Advanced Topics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Azure App Service plans, networking for an App Service, and container images.",
                        "Understanding how to deploy and manage web apps and APIs using Azure App Services."





                    ]
                }
            ]
        },
        {
            title: "Introduction to Azure Database for PostgreSQL",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Azure Databases for PostgreSQL",
                        "Relational Database as a Service (DBaaS) Explained",
                        "Advantages and Implementation Options of PostgreSQL in Azure",
                        "Understanding Deployment Models: Single Server, Flexible Server, HyperScale (Citus)"





                    ]
                }
            ]
        },
        {
            title: "Azure Fundamentals for PostgreSQl",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Key Azure Resources and Services for Database Management",
                        "Managing Storage in Azure: Blob Storage, File Shares, and Storage Tiers",
                        "Azure Active Directory (AAD): Creating Users and Managing Permissions",
                        "Service Principals and Identity Access Management (IAM)"





                    ]
                }
            ]
        },
        {
            title: "Deployment and Configuration of PostgreSQL on Azure",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Steps to Create and Configure PostgreSQL Server Instances on Azure",
                        "Flexible Server Deployment Options and Their Benefits",
                        "Security Configurations: Firewall Settings and Connection Security",
                        "Database Creation, Data Insertion, and Query Performance in Azure"





                    ]
                }
            ]
        },
        {
            title: "Migration and Data Movement",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Strategies for Migrating Databases from On-Premise to Azure",
                        "Using Azure Data Movement Service (DMS) for Efficient Migrations",
                        "Handling Schema Migrations and Network Configurations",
                        "Best Practices for Using Virtual Networks and Security Groups during Migration"





                    ]
                }
            ]
        },
        {
            title: "Monitoring,Security,and High Availability",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Strategies for Migrating Databases from On-Premise to Azure",
                        "Using Azure Data Movement Service (DMS) for Efficient Migrations",
                        "Handling Schema Migrations and Network Configurations",
                        "Best Practices for Using Virtual Networks and Security Groups during Migration"





                    ]
                }
            ]
        },
        {
            title: "Introduction to Cloud Computing and DevOps for Data",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Fundamentals: Overview of cloud service models (IaaS, PaaS, SaaS) and deployment models (public, private, hybrid).",
                        "Basics of DevOps: Understanding the DevOps culture, practices, and its significance in cloud environments",
                        "Data on the Cloud: Exploring cloud storage solutions, databases, and big data services provided by major cloud providers (AWS, Azure, Google Cloud)",
                        "Introduction to Infrastructure as Code (IaC): Concepts and tools for managing infrastructure through code."





                    ]
                }
            ]
        },
        {
            title: "Cloud Data Storage and Databases",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Cloud Storage Solutions: Differences between object storage, file storage, and block storage. Use cases for each",
                        "Cloud Databases: Overview of relational and NoSQL database services in the cloud (e.g., AWS RDS, Azure SQL Database, Google Cloud Firestore)",
                        "Data Warehousing and Big Data Solutions: Introduction to cloud-based data warehousing services (e.g., Amazon Redshift, Google BigQuery, Azure Synapse Analytics).",
                        "Data Migration to Cloud: Strategies and tools for migrating data to cloud environments."





                    ]
                }
            ]
        },
        {
            title: "Automating Data Pipelines With DevOps Practices",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Automated Data Pipelines: Designing and implementing automated data pipelines using cloud services",
                        "Continuous Integration and Continuous Delivery (CI/CD) for Data: Applying CI/CD practices to data pipeline development, including version control, testing, and deployment strategies",
                        "Monitoring and Logging: Tools and practices for monitoring cloud resources and data pipelines, understanding logs and metrics for troubleshooting",
                        "Infrastructure as Code (IaC) for Data Systems: Using IaC tools (e.g., Terraform, CloudFormation) to provision and manage cloud data infrastructure"





                    ]
                }
            ]
        },
        {
            title: "Advanced Topics in Data Cloud and DevOps",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Serverless Data Processing: Leveraging serverless architectures for data processing tasks (e.g., AWS Lambda, Azure Functions)",
                        "Containerization and Data Services: Using containers (e.g., Docker, Kubernetes) for deploying and scaling data applications and services in the cloud",
                        "Machine Learning and AI in the Cloud: Introduction to cloud-based machine learning services and integrating AI capabilities into data pipelines",
                        "Data Analytics and Visualization: Tools and services for analyzing and visualizing data directly in the cloud (e.g., Amazon QuickSight, Google Data Studio, Power BI on Azure)"





                    ]
                }
            ]
        },
    ],
    tableau: [
        {
            title: "Excel",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Excel:",
                        "Customizing tabs, options in excel",
                        "Name managers",
                        "Data validation: Options in data validation for list, whole numbers, dates",
                        "Using name manager for data validation",
                        "Sorting:",
                        "Custom sorting",
                        "Subtotals",
                        "Sorting left to right",
                        "Advanced sorting with multiple options",
                        "Advanced filter options",
                        "Sorting and filtering with color options",
                        "Pivot tables",
                        "Short cuts to create pivot tables",
                        "Changing row and column labels",
                        "Custom format tables and default tables",
                        "Changing number formats",
                        "Value field settings and summarizing values by 11 different options",
                        "Value field settings and showing values as different options",
                        "Grouping of continuous fields",
                        "Pivot charts, compared regular charts",
                        "Slicers, Slicer settings, advanced slicer",
                        "Calculated fields in pivot tables",
                        "Vlookup and Hlookup:",
                        "Syntax for Vlookup",
                        "What-if there are errors",
                        "Vlookup with data validation",
                        "Approximation for Vlookup",
                        "Using column function for dynamic column numbers",
                        "Using Choose function to select a table for Vlookup",
                        "Using Match function to identify column number",
                        "Locking cells for absolute and relative cells",
                        "Formulae",
                        "Auto sum functions",
                        "Logical functions",
                        "Text functions",
                        "Date and Time functions",
                        "Lookup and reference functions",
                        "Information functions",
                        "Charts:",
                        "Column charts",
                        "Line charts",
                        "Pie charts, Pie in pie and Bar of pie, Donut",
                        "Stacker bar and clustered bar charts",
                        "Area",
                        "Scatter charts",
                        "Radar charts",
                        "Tree maps",
                        "Histogram",
                        "Waterfall",
                        "Funnel",
                        "Combo",
                        "Conditional formatting:",
                        "Duplicate values",
                        "Alternate rows",
                        "Multiple criteria",
                        "Negative numbers",
                        "Gantt charts and Formula",
                        "Advanced topics:",
                        "What-if analysis",
                        "Text to columns",
                        "Flash fill",
                        "Remove duplicates, Consolidate",
                        "Grouping, ungrouping and sub-totals",
                        "Freezing and unfreezing panes"

                    ]
                }
            ]
        },
        {
            title: "Tableau Introduction",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding the start pane",
                        "Connecting to data source",
                        "Data sources that can be connected",
                        "Various file formats",
                        "Bookmarks",
                        "Connecting to data source",
                        "TDS, TDE",
                        "Connecting to excel, Joins, Splitting data",
                        "Live and extract",
                        "Dimensions and measures",
                        "Clearing sorts and filters",
                        "Views: Standard fit width and height",
                        "Drilling down",
                        "Expanding the marks in pane",
                        "Swapping axis",
                        "Renaming sheets",
                        "Editing color pane",
                        "Adding highlighters",
                        "Understanding show me",
                        "Sorting and hierarchy",
                        "Data pane and analytics pane",
                        "Different view options at bottom of sheet"


                    ]
                }
            ]
        },
        {
            title: "Managing Data And Extracts in Tableau",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Hiding and unhiding fields",
                        "Creating folders to move dimensions and measures",
                        "Adding default colors and properties",
                        "Adding multiple data sources",
                        "Extracting workbook",
                        "Replacing data sources",
                        "Data cleansing",
                        "Database joins",
                        "Blending"

                    ]
                }
            ]
        },
        {
            title: "Sorting And Filtering in Tableau",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Default charts",
                        "Highlighter for color and shape",
                        "Sorting from axis, color, category, manually and clearing sort",
                        "Creating groups from pane, manually, visually, parameters, and bins",
                        "Adding filter, show filter, wildcards, Top N parameters",
                        "Discrete and continuous dates",
                        "Types of filters: Applying to specific sheets, Editing page shelf",
                        "Hiding cards"

                    ]
                }
            ]
        },
        {
            title: "Sets And Parameters in Tableau",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Sets",
                        "Parameters",
                        "Tool tips",
                        "Cluster analysis",
                        "Formatting"

                    ]
                }
            ]
        },
        {
            title: "Dashboards And Storyboards in Tableau",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Building dashboards",
                        "Hiding and unhiding sheets",
                        "Interface between sheets, dashboard and storyboard",
                        "Elements in dashboard",
                        "Formatting",
                        "Actions in dashboard",
                        "Device designer",
                        "Story points"

                    ]
                }
            ]
        },
        {
            title: "Charts In Tableau",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Word cloud",
                        "Bump charts",
                        "Box and whisker",
                        "Funnel",
                        "Step and Line",
                        "Pareto",
                        "Waterfall",
                        "Donut",
                        "Lollipop",
                        "Pie",
                        "Heat map",
                        "Waffle",
                        "Show me charts"

                    ]
                }
            ]
        },
        {
            title: "Calculations In Tableau",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Basic syntax",
                        "Regular calc and table calc",
                        "Adding totals",
                        "Date calc",
                        "Logic calc",
                        "String calc",
                        "Number calc",
                        "LODs"

                    ]
                }
            ]
        },
        {
            title: "Maps in Tableau",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Mapbox",
                        "WMS>Layers",
                        "Converting geo to non-geo",
                        "Chart default",
                        "Options for maps",
                        "Unrecognized locations",
                        "Groups"

                    ]
                }
            ]
        },
        {
            title: "SQL",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Intro",
                        "Syntax",
                        "Select",
                        "Distinct",
                        "Where",
                        "And Or Not",
                        "Order By",
                        "Insert into",
                        "Null Values",
                        "Update",
                        "Delete",
                        "Top",
                        "Min and Max",
                        "Count,Avg,Sum",
                        "Like",
                        "Wildcards",
                        "In",
                        "Between",
                        "Alias",
                        "Joins",
                        "Inner",
                        "Left",
                        "Right",
                        "Full",
                        "Self",
                        "Union",
                        "Group By",
                        "Having",
                        "Exists",
                        "Create Table",
                        "Drop Table",
                        "Not null",
                        "Unique",
                        "Primary key",
                        "Default",
                        "Views",
                        "Operators",
                        ""

                    ]
                }
            ]
        },
    ],
    artificialIntelligence: [
        {
            title: "Applications & Web Technologies",
            content: [
                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                        "Web Technologies used in Projects"
                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle (SDLC)",
            content: [
                {
                    title: "Topics",
                    content: [
                        " Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process",
                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices"
                    ]
                }
            ]
        },
        {
            title: "Python Basics for AI ",
            content: [
                {
                    title: "Topics",
                    content: [
                        "Python Syntax and Basic Constructs",
                        "Control Flow and Functions",
                        "Object-Oriented Programming in Python"


                    ]
                }
            ]
        },
        {
            title: "Data Handling with Python",
            content: [

                {
                    title: "Topics",
                    content: [
                        "NumPy for Numerical Data",
                        "Pandas for Data Cleaning and Preparation",
                        "Data Visualization with Matplotlib and Seaborn"


                    ]
                }
            ]
        },
        {
            title: "Intermediate Python for AI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Working with APIs and Web Data",
                        "Introduction to Web Scraping",
                        "File Handling and Data Persistence"



                    ]
                }
            ]
        },
        {
            title: "Advanced Python Concepts",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Advanced Data Structures",
                        "Python Decorators, Generators, and Context Managers",
                        "Multithreading and Multiprocessing for Performance Optimization"




                    ]
                }
            ]
        },
        {
            title: "Python Libraries for AI Development",
            content: [

                {
                    title: "Topics",
                    content: [
                        "TensorFlow Basics",
                        "Keras for Deep Learning Models",
                        "PyTorch Introduction"

                    ]
                }
            ]
        },
        {
            title: "Linear Algebra for AI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Vectors, Matrices, and Linear Transformations",
                        "Eigenvalues and Eigenvectors",
                        "Application in AI and ML"



                    ]
                }
            ]
        },
        {
            title: "Calculus and Optimization for AI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Differential Calculus and Gradients",
                        "Optimization Algorithms",
                        "Application in Neural Network Training"

                    ]
                }
            ]
        },
        {
            title: "Probability Theory for AI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Basics of Probability",
                        "Probability Distributions",
                        "Bayesian Thinking in AI"

                    ]
                }
            ]
        },
        {
            title: "Statistical Foundations for AI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Descriptive Statistics and Inferential Statistics",
                        "Hypothesis Testing and Confidence Intervals",
                        "Correlation vs. Causation"


                    ]
                }
            ]
        },
        {
            title: "Advanced Mathematics for AI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Discrete Mathematics Concepts",
                        "Graph Theory and Network Models",
                        "Continuous Optimization and Constraint Satisfaction"

                    ]
                }
            ]
        },
        {
            title: "Machine Learning Foundations",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Types of Machine Learning: Supervised, Unsupervised, and Reinforcement",
                        "Bias-Variance Tradeoff",
                        "Evaluating Machine Learning Models"


                    ]
                }
            ]
        },
        {
            title: "Supervised Learning Deep Dive",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Classification Algorithms",
                        "Regression Analysis",
                        "Ensemble Learning Methods"

                    ]
                }
            ]
        },
        {
            title: "Unsupervised Learning and Clustering",
            content: [

                {
                    title: "Topics",
                    content: [
                        "K-means and Hierarchical Clustering",
                        "Dimensionality Reduction Techniques: PCA, t-SNE",
                        "Association Rule Learning"


                    ]
                }
            ]
        },
        {
            title: "Time Series Analysis and Forecasting",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Time Series Data and Its Components",
                        "ARIMA and Seasonal ARIMA",
                        "Using Machine Learning for Time Series Prediction"

                    ]
                }
            ]
        },
        {
            title: "Reinforcement Learning Basics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Principles of Reinforcement Learning",
                        "Markov Decision Processes",
                        "Implementing Q-Learning and Deep Q-Networks (DQN)"


                    ]
                }
            ]
        },
        {
            title: "Introduction to Deep Learning",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding Deep Learning and Neural Networks",
                        "Activation Functions, Loss Functions, and Optimization Techniques",
                        "Building Your First Neural Network"

                    ]
                }
            ]
        },
        {
            title: "Convolution Neural Networks(CNNs)",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to CNNs and Their Architecture",
                        "Implementing CNNs for Image Recognition and Classification",
                        "Advanced CNN Techniques for Computer Vision Prediction"

                    ]
                }
            ]
        },
        {
            title: "Recurrent Neural Networks (RNNs)and LSTMs",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding RNNs and Their Applications",
                        "Long Short-Term Memory Networks (LSTMs) for Sequential Data Processing",
                        "Use Cases: Text Generation, Time Series Forecasting"

                    ]
                }
            ]
        },
        {
            title: "Generative Adversarial Networks (GANs)and Autoencoders",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Fundamentals of GANs and Their Architecture",
                        "Building Simple Generative Models with GANs",
                        "Exploring Autoencoders for Data Compression and Generation"

                    ]
                }
            ]
        },
        {
            title: "Introduction to Transformer Models",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Basics of Attention Mechanisms and Transformer Architecture",
                        "Implementing Transformer Models for NLP Tasks",
                        "Understanding BERT, GPT, and Other Variants"

                    ]
                }
            ]
        },
        {
            title: "Natural Language Processing (NLP) Applications",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Text Preprocessing and Feature Extraction Techniques",
                        "Sentiment Analysis, Named Entity Recognition (NER), and Text Summarization",
                        "Chatbots and Language Models"

                    ]
                }
            ]
        },
        {
            title: "AI in Computer Vision",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Object Detection and Image Segmentation Techniques",
                        "Facial Recognition Systems",
                        "Autonomous Vehicles and Drone Technology"

                    ]
                }
            ]
        },
        {
            title: "Reinforcement Learning Applications",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deep Reinforcement Learning for Game Playing",
                        "Applying RL in Robotics and Autonomous Systems",
                        "RL in Finance and Healthcare"

                    ]
                }
            ]
        },
        {
            title: "Ethical AI and Governance",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Ensuring Fairness and Transparency in AI Models",
                        "Data Privacy and Security in AI Applications",
                        "AI Governance and Regulatory Compliance"

                    ]
                }
            ]
        },
        {
            title: "Innovative AI Tecnologies",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Quantum Computing for AI",
                        "Edge AI and Its Applications",
                        "The Future of AI: Trends and Predictions"

                    ]
                }
            ]
        },
        {
            title: "Cloud Platforms for AI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of AWS, Google Cloud, and Azure for AI",
                        "Leveraging Cloud AI Services for Model Training and Deployment",
                        "Big Data Technologies and AI: Integrating Apache Spark and Hadoop"

                    ]
                }
            ]
        },
        {
            title: "MLOps:Best Practices",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of AWS, Google Cloud, and Azure for AI",
                        "Leveraging Cloud AI Services for Model Training and Deployment",
                        "Big Data Technologies and AI: Integrating Apache Spark and Hadoop"

                    ]
                }
            ]
        },
        {
            title: "Deploying AI Models",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Techniques for Model Deployment: API, Docker Containers, and Microservices",
                        "Scalability and Performance Optimization",
                        "User Interfaces for AI Applications"

                    ]
                }
            ]
        },
        {
            title: "Security and Monitoring of AI Systems",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Security Best Practices in AI Deployment",
                        "Techniques for Monitoring AI Systems for Anomalies and Performance Issues",
                        "Automated Remediation and Alerting Strategies"

                    ]
                }
            ]
        },
        {
            title: "Future Directions in AI Deployment",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Emerging Tools and Platforms for AI Deployment",
                        "Ethics and Responsible AI in Deployment",
                        "Preparing for Future Technological Advances in AI"

                    ]
                }
            ]
        },
    ],
    powerBi: [
        {
            title: "Applications & Web Technologies",
            content: [

                {
                    title: "Topics",
                    content: [
                        "What is an Application?",
                        "Types of Applications",
                        "Fundamentals of Web Applications",
                        "Web Application Architecture",
                        "Web Technologies used in Projects"

                    ]
                }
            ]
        },
        {
            title: "Software Development Life Cycle(SDLC)",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Software Development Life Cycle",
                        "Application Lifecycle Management - ALM",
                        "SDLC Methodologies",
                        "DevOps Process"

                    ]
                }
            ]
        },
        {
            title: "Agile and Scrum",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction To Agile & Scrum",
                        "The Principles of Agile Methodology",
                        "Scrum Framework: Roles, Artifacts, and Events",
                        "Implementing Agile with Scrum",
                        "Agile Project Management Best Practices"

                    ]
                }
            ]
        },
        {
            title: "Introduction to Power BI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Analytics and Power BI Tools Suite",
                        "Career Opportunities and Job Roles in Power BI",
                        "Power BI Data Analyst (PL 300) Certification Overview",
                        "Introduction to AI Visuals and Features in Power BI"

                    ]
                }
            ]
        },
        {
            title: "Basic Report Design",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding the Power BI Ecosystem and Architecture",
                        "Data Sources and Types for Power BI Reporting",
                        "Power BI Design Tools and Desktop Tool Installation",
                        "Exploring Power BI Desktop Interface: Data View, Report View, and Canvas"

                    ]
                }
            ]
        },
        {
            title: "Visual Interaction and Synchronization",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Visual Interaction Techniques in Reports",
                        "Using Slicers for Dynamic Report Filtering",
                        "Managing Report Pages and Visual Sync Limitations"

                    ]
                }
            ]
        },
        {
            title: "Grouping and Hierarchies in Power BI",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Implementing Grouping and Binning in Reports",
                        "Creating and Utilizing Hierarchies for Drill-Down Reports"

                    ]
                }
            ]
        },
        {
            title: "Basic Data Transformation with Power Query",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Power Query M Language",
                        "Basic Data Transformations in Power Query",
                        "Understanding Query Duplication and Grouping"

                    ]
                }
            ]
        },
        {
            title: "Power BI Service(Cloud)Basics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Power BI Cloud Components and App Workspaces",
                        "Creating and Managing Reports and Dashboards in Power BI Cloud",
                        "Sharing, Subscribing, and Exporting Reports in Power BI Cloud"

                    ]
                }
            ]
        },
        {
            title: "Introduction to Data Analysis Expressions(DAX)",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding the Importance of DAX in Power BI",
                        "Learning Basic DAX Syntax, Data Types, and Contexts",
                        "Simple Measures and Calculations with DAX"

                    ]
                }
            ]
        },
        {
            title: "Advanced Report Design and Visualization",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Accessing Big Data Sources and Azure Databases",
                        "Advanced Filtering Techniques and Utilizing Bookmarks",
                        "Implementing Various Chart Types and Map Visuals"

                    ]
                }
            ]
        },
        {
            title: "Advanced Power Query Techniques",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deep Dive into Advanced Data Cleaning and Preparation Techniques",
                        "Implementing Parameter Queries for Dynamic Data Loads",
                        "Creating and Managing Parameters in Power Query"

                    ]
                }
            ]
        },
        {
            title: "Advanced Power BI Cloud Features",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Configuring and Managing Gateways for Data Refresh",
                        "Utilizing Workbooks and Excel Online with Power BI Cloud",
                        "Creating and Managing Power BI Apps"

                    ]
                }
            ]
        },
        {
            title: "Complex DAX Functions and Data Modeling",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Implementing Quick Measures and Advanced Calculations",
                        "Data Modeling and Relationship Management in DAX",
                        "Mastering Variables and Dynamic Expressions in DAX"

                    ]
                }
            ]
        },
        {
            title: "Expert DAX Techniques and Security",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Advanced DAX Functions for Time Intelligence",
                        "Implementing Row Level Security (RLS) with DAX",
                        "Utilizing DAX for Custom Analytics and Reporting"

                    ]
                }
            ]
        },
        {
            title: "Power BI Administration and Report Server",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Configuring Power BI Report Server",
                        "Understanding Power BI Administration and AI Features",
                        "Managing Security and Administration in Power BI"

                    ]
                }
            ]
        },
        {
            title: "Real-time Project,Deployment,and Career Advancement",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Power BI Cloud Components and App Workspaces",
                        "Creating and Managing Reports and Dashboards in Power BI Cloud",
                        "Sharing, Subscribing, and Exporting Reports in Power BI Cloud"

                    ]
                }
            ]
        },
        {
            title: "Excel Essential for Data Analysis",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Excel: Interface, Basic Operations, and Managing Worksheets",
                        "Fundamental Data Operations: Sorting, Filtering, and Conditional Formatting",
                        "Basic Formulas and Functions: Sum, Average, Logical Functions (IF, AND, OR), and Text Functions (LEFT, RIGHT, CONCATENATE)"

                    ]
                }
            ]
        },
        {
            title: "Data Management and Visualization",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Advanced Data Management: Data Validation, Advanced Filtering, and Named Ranges",
                        "Creating and Managing Tables for Efficient Data Analysis",
                        "Introduction to Data Visualization: Creating and Customizing Charts (Bar, Line, Pie), and Using Sparklines"

                    ]
                }
            ]
        },
        {
            title: "Mastering PivotTables and Introduction to Data Cleanup",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Comprehensive Guide to PivotTables: Creating, Customizing, Slicers, and Timelines",
                        "Basic to Advanced PivotTable Techniques: Grouping Data, Calculated Fields",
                        "Data Cleanup Techniques: Removing Duplicates, Text to Columns, Flash Fill"

                    ]
                }
            ]
        },
        {
            title: "Advanced Excel Functions and Power Tools",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Mastering Lookup Functions: VLOOKUP, HLOOKUP, XLOOKUP",
                        "Introduction to Power Query for Data Transformation and Cleaning",
                        "Power Pivot and DAX Basics: Creating Data Models, Introduction to DAX Formulas for Data Analysis"

                    ]
                }
            ]
        },
        {
            title: "Automation,Advanced Visualization,and Collaboration",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Automating Tasks with Macros and an Introduction to VBA for Custom Functions",
                        "Advanced Chart Techniques and Creating Interactive Dashboards",
                        "Workbook Protection, Sharing Workbooks for Collaboration, Documenting and Auditing Workbooks"

                    ]
                }
            ]
        },
        {
            title: "Introduction to Terraform",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Databases and SQL: Understanding relational databases and the role of SQL.",
                        "SQL Syntax Overview: Keywords, statements, and clauses.",
                        "Basic SQL Commands: SELECT, FROM, WHERE, and ORDER BY.",
                        "Filtering Data: Using conditions to retrieve specific data (AND, OR, NOT)."

                    ]
                }
            ]
        },
        {
            title: "Working with Multiple Tables",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding Table Relationships: Primary keys, foreign keys, and the importance of relationships in databases.",
                        "Join Operations: INNER JOIN, LEFT JOIN, RIGHT JOIN, and FULL JOIN.",
                        "Subqueries and Nested Queries: Using subqueries in the SELECT, FROM, and WHERE clauses.",
                        "Aggregating Data: Using GROUP BY and aggregate functions (COUNT, SUM, AVG, MIN, MAX)."

                    ]
                }
            ]
        },
        {
            title: "Advanced Data Manipulation",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Data Manipulation Commands: INSERT, UPDATE, DELETE.",
                        "Managing Tables: Creating and altering tables (CREATE TABLE, ALTER TABLE, DROP TABLE).",
                        "Advanced Filtering Techniques: Using LIKE, IN, BETWEEN, and wildcard characters.",
                        "Working with Dates and Times: Understanding and manipulating date and time data."

                    ]
                }
            ]
        },
        {
            title: "Complex Queries and Optimization",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Advanced SQL Functions: String functions, mathematical functions, and date functions.",
                        "Window Functions: Overviews of ROW_NUMBER, RANK, DENSE_RANK, LEAD, LAG, and their applications.",
                        "Query Performance Optimization: Indexes, query planning, and execution paths.",
                        "Common Table Expressions (CTEs): Writing cleaner and more readable queries with WITH clause."

                    ]
                }
            ]
        },
        {
            title: "SQL for Data Analysis Specifics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Analytical SQL for Reporting: Building complex queries to answer analytical questions.",
                        "Pivoting Data: Transforming rows to columns (PIVOT) and columns to rows (UNPIVOT).",
                        "Data Warehousing Concepts: Introduction to data warehousing practices and how they apply to SQL querying.",
                        "Integrating SQL with Data Analysis Tools: Connecting SQL databases with tools like Excel, Power BI, and Python for deeper data analysis."

                    ]
                }
            ]
        },
        {
            title: "Python Programming Fundamentals",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Python's history, key features, and comparison with other languages.",
                        "Setting up the Python environment, writing your first program",
                        "Variables, data types, conditional statements, loops, control flow.",
                        "Introduction to strings, string manipulation, and basic functions."

                    ]
                }
            ]
        },
        {
            title: "Advanced Python Concepts and Collections",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deep Dive into Collections",
                        "Functional Programming in Python",
                        "Object-Oriented Programming (OOP)"

                    ]
                }
            ]
        },
        {
            title: "Exception Handling and File Management in Python",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Mastering Exception Handling",
                        "File Handling Essentials",
                        "Database Programming"

                    ]
                }
            ]
        },
        {
            title: "Developing Web Applications with Python",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Getting Started with Flask",
                        "Exploring Django"


                    ]
                }
            ]
        },
        {
            title: "Automation,GUI Programming,and Version Control",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Automation and Scripting",
                        "GUI Development with TKinter",
                        "Version Control with Git"

                    ]
                }
            ]
        },
        {
            title: "Introduction to Cloud Computing and DevOps for Data",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Fundamentals: Overview of cloud service models (IaaS, PaaS, SaaS) and deployment models (public, private, hybrid).",
                        "Basics of DevOps: Understanding the DevOps culture, practices, and its significance in cloud environments.",
                        "Data on the Cloud: Exploring cloud storage solutions, databases, and big data services provided by major cloud providers (AWS, Azure, Google Cloud).",
                        "Introduction to Infrastructure as Code (IaC): Concepts and tools for managing infrastructure through code."

                    ]
                }
            ]
        },
        {
            title: "Cloud Data Storage and Databases",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Cloud Storage Solutions: Differences between object storage, file storage, and block storage. Use cases for each.",
                        "Cloud Databases: Overview of relational and NoSQL database services in the cloud (e.g., AWS RDS, Azure SQL Database, Google Cloud Firestore).",
                        "Data Warehousing and Big Data Solutions: Introduction to cloud-based data warehousing services (e.g., Amazon Redshift, Google BigQuery, Azure Synapse Analytics).",
                        "Data Migration to Cloud: Strategies and tools for migrating data to cloud environments."

                    ]
                }
            ]
        },
        {
            title: "Automating Data Pipelines with DevOps Practices",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Automated Data Pipelines: Designing and implementing automated data pipelines using cloud services.",
                        "Continuous Integration and Continuous Delivery (CI/CD) for Data: Applying CI/CD practices to data pipeline development, including version control, testing, and deployment strategies.",
                        "Monitoring and Logging: Tools and practices for monitoring cloud resources and data pipelines, understanding logs and metrics for troubleshooting.",
                        "Infrastructure as Code (IaC) for Data Systems: Using IaC tools (e.g., Terraform, CloudFormation) to provision and manage cloud data infrastructure."

                    ]
                }
            ]
        },
        {
            title: "Advanced Topics in Data Cloud and DevOps",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Serverless Data Processing: Leveraging serverless architectures for data processing tasks (e.g., AWS Lambda, Azure Functions).",
                        "Containerization and Data Services: Using containers (e.g., Docker, Kubernetes) for deploying and scaling data applications and services in the cloud.",
                        "Machine Learning and AI in the Cloud: Introduction to cloud-based machine learning services and integrating AI capabilities into data pipelines.",
                        "Data Analytics and Visualization: Tools and services for analyzing and visualizing data directly in the cloud (e.g., Amazon QuickSight, Google Data Studio, Power BI on Azure)."

                    ]
                }
            ]
        },
        {
            title: "SQL Fundamentals",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Introduction to Databases and SQL: Understanding relational databases and the role of SQL.",
                        "SQL Syntax Overview: Keywords, statements, and clauses.",
                        "Basic SQL Commands: SELECT, FROM, WHERE, and ORDER BY",
                        "Filtering Data: Using conditions to retrieve specific data (AND, OR, NOT)."

                    ]
                }
            ]
        },
        {
            title: "Working with Multiple Tables",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Understanding Table Relationships: Primary keys, foreign keys, and the importance of relationships in databases.",
                        "Join Operations: INNER JOIN, LEFT JOIN, RIGHT JOIN, and FULL JOIN.",
                        "Subqueries and Nested Queries: Using subqueries in the SELECT, FROM, and WHERE clauses.",
                        "Aggregating Data: Using GROUP BY and aggregate functions (COUNT, SUM, AVG, MIN, MAX)."

                    ]
                }
            ]
        },
        {
            title: "Advanced Data Manipulation",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Data Manipulation Commands: INSERT, UPDATE, DELETE.",
                        "Managing Tables: Creating and altering tables (CREATE TABLE, ALTER TABLE, DROP TABLE).",
                        "Advanced Filtering Techniques: Using LIKE, IN, BETWEEN, and wildcard characters.",
                        "Working with Dates and Times: Understanding and manipulating date and time data."

                    ]
                }
            ]
        },
        {
            title: "Complex Queries and Optimization",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Advanced SQL Functions: String functions, mathematical functions, and date functions.",
                        "Window Functions: Overviews of ROW_NUMBER, RANK, DENSE_RANK, LEAD, LAG, and their applications.",
                        "Query Performance Optimization: Indexes, query planning, and execution paths.",
                        "Common Table Expressions (CTEs): Writing cleaner and more readable queries with WITH clause."

                    ]
                }
            ]
        },
        {
            title: "SQL for Data Analysis Specifics",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Analytical SQL for Reporting: Building complex queries to answer analytical questions.",
                        "Pivoting Data: Transforming rows to columns (PIVOT) and columns to rows (UNPIVOT).",
                        "Data Warehousing Concepts: Introduction to data warehousing practices and how they apply to SQL querying.",
                        "Integrating SQL with Data Analysis Tools: Connecting SQL databases with tools like Excel, Power BI, and Python for deeper data analysis."

                    ]
                }
            ]
        },
        {
            title: "Python Programming Fundamentals",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Overview of Python's history, key features, and comparison with other languages.",
                        "Setting up the Python environment, writing your first program..",
                        "Core Programming Concepts.",
                        "Variables, data types, conditional statements, loops, control flow.",
                        "Introduction to strings, string manipulation, and basic functions."

                    ]
                }
            ]
        },
        {
            title: "Advanced Python Concepts and Collections",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Deep Dive into Collections",
                        "Understanding lists, tuples, dictionaries, sets, and frozen sets.",
                        "Functions, methods, and comprehensions for collections.",
                        "Functional Programming in Python",
                        "Exploring function arguments, anonymous functions, and special functions (map, reduce, filter).",
                        "Object-Oriented Programming (OOP)",
                        "Classes, objects, constructors, destructors, inheritance, polymorphism.",
                        "Encapsulation, data hiding, magic methods, and operator overloading."

                    ]
                }
            ]
        },
        {
            title: "Exception Handiling and File Management in Python",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Mastering Exception Handling",
                        "Exception handling mechanisms, try & finally clauses, user-defined exceptions.",
                        "Basics of file operations, handling Excel and CSV files",
                        "Database Programming",
                        "Introduction to database connections and operations with MySQL."

                    ]
                }
            ]
        },
        {
            title: "Developing Web Applications with Python",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Getting Started with Flask",
                        "Setting up Flask, creating simple applications, routing, and middleware.",
                        "Exploring Django",
                        "Introduction to Django, MVC model, views, URL mapping."

                    ]
                }
            ]
        },
        {
            title: "AUtomation,GUI Programming,and Version Control",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Automation and Scripting",
                        "Enhancing file handling, database automation, and web scraping with BeautifulSoup.",
                        "GUI Development with TKinter",
                        "Basics of TKinter for developing desktop applications.",
                        "Version Control with Git",
                        "Managing projects with Git, understanding repository management, commits, merging, and basic Git commands."

                    ]
                }
            ]
        },
        {
            title: "Introduction to Cloud Computing and DevOps for Data",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Cloud Computing Fundamentals: Overview of cloud service models (IaaS, PaaS, SaaS) and deployment models (public, private, hybrid).",
                        "Basics of DevOps: Understanding the DevOps culture, practices, and its significance in cloud environments.",
                        "Data on the Cloud: Exploring cloud storage solutions, databases, and big data services provided by major cloud providers (AWS, Azure, Google Cloud).",
                        "Introduction to Infrastructure as Code (IaC): Concepts and tools for managing infrastructure through code."

                    ]
                }
            ]
        },
        {
            title: "Cloud Data Storage and Databases",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Cloud Storage Solutions: Differences between object storage, file storage, and block storage. Use cases for each.",
                        "Cloud Databases: Overview of relational and NoSQL database services in the cloud (e.g., AWS RDS, Azure SQL Database, Google Cloud Firestore).",
                        "Data Warehousing and Big Data Solutions: Introduction to cloud-based data warehousing services (e.g., Amazon Redshift, Google BigQuery, Azure Synapse Analytics).",
                        "Data Migration to Cloud: Strategies and tools for migrating data to cloud environments."

                    ]
                }
            ]
        },
        {
            title: "Automating Data Pipelines with DevOps Practices",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Automated Data Pipelines: Designing and implementing automated data pipelines using cloud services.",
                        "Continuous Integration and Continuous Delivery (CI/CD) for Data: Applying CI/CD practices to data pipeline development, including version control, testing, and deployment strategies.",
                        "Monitoring and Logging: Tools and practices for monitoring cloud resources and data pipelines, understanding logs and metrics for troubleshooting.",
                        "Infrastructure as Code (IaC) for Data Systems: Using IaC tools (e.g., Terraform, CloudFormation) to provision and manage cloud data infrastructure."

                    ]
                }
            ]
        },
        {
            title: "Advanced Topics in Data Cloud and DevOps",
            content: [

                {
                    title: "Topics",
                    content: [
                        "Serverless Data Processing: Leveraging serverless architectures for data processing tasks (e.g., AWS Lambda, Azure Functions).",
                        "Containerization and Data Services: Using containers (e.g., Docker, Kubernetes) for deploying and scaling data applications and services in the cloud.",
                        "Machine Learning and AI in the Cloud: Introduction to cloud-based machine learning services and integrating AI capabilities into data pipelines.",
                        "Data Analytics and Visualization: Tools and services for analyzing and visualizing data directly in the cloud (e.g., Amazon QuickSight, Google Data Studio, Power BI on Azure)."

                    ]
                }
            ]
        },
    ],
};

export default curriculumData;
