import React from 'react'
import Header from '../../Components/Headers';
import { useNavigate } from 'react-router-dom';
import CourceDetails from './CourceDetails';
import Curriculum from './Curriculum';

const ContentPage = ({ skill }) => {
  console.log('::: recived skill at contentPage :::', skill)

  const courseData = {
    overview: `Propel your career and become a ${skill} with our comprehensive Data Science Course. Gain expertise in in-demand skills like Python, SQL, Excel, Machine Learning, Power BI, Computer Vision, Generative AI, and more. Dive deep into data interpretation nuances, Machine Learning, and enhance your programming skills to elevate your Data Science career.`,
    highlights: [
      "One-on-One with Industry",
      "Placement Assistance",
      "1:1 Mock Interview",
    ],
    details: [
      { title: "Flexible Timings", description: "" },
      { title: "36 Hours Training", description: "" },
      { title: "Certification", description: "" },
      { title: "24/7 Support", description: "" },
    ],
    whoCanApply: [
      "Individuals with a bachelor's degree and a keen interest to learn AI and Data Science",
      "IT professionals looking for a career transition as Data Scientists and Artificial Intelligence Engineers",
    ],
  };


  return (
    <div>
      <Header />
      <div className="contentbackGround"></div>
      <CourceDetails
        overview={courseData.overview}
        highlights={courseData.highlights}
        details={courseData.details}
        whoCanApply={courseData.whoCanApply}
        skill={skill}
      />
      <Curriculum skill={skill} />
    </div>
  )
}

export default ContentPage;
