import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './styles.scss';
import Header from '../../Components/Headers';
import Footer from '../../Components/Footer/Footer';
import ScrollBar from '../../Components/ScrollBar/ScrollBar';
import Alert from '@mui/material/Alert';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    // Initialize EmailJS
    emailjs.init('YOUR_USER_ID');
    // Verify if the user ID is set correctly
    console.log('myUserId................', emailjs);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};

    if (!formData.name.trim()) {
      errors.name = 'Full name is required';
    }

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!formData.message.trim()) {
      errors.message = 'Comment is required';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      emailjs.send(
        'service_j6lqvcb',
        'template_8fzmx04',
        formData,
        'n-bK10WXHGKof-q49'
      ).then((result) => {
        console.log("::::::::::::::", result);
        // <Alert severity="success">This is a success Alert.</Alert>
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        setAlertSeverity('success');
        setAlertMessage('Email sent successfully!');
        setIsEmailSent(true);
        setTimeout(() => setIsEmailSent(false), 10000);
      }, (error) => {
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        setAlertSeverity('error');
        setAlertMessage('Failed to send email.');
        setIsEmailSent(true);
        setTimeout(() => setIsEmailSent(false), 30000);

        setIsEmailSent(false);
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="contact-page">
      <Header />
      <div className="banner"></div>
      <div className="contact-container">
        <div className="locations">
          <h2>Locations</h2>
          <div className="location">
            <h3>INDIA</h3>
            <p>HYDERABAD</p>
            <p>1st floor Sri Swaroopam building, 7-1-414/1, beside Hanuman temple, East Srinivas Nagar Colony, Srinivasa Nagar, Sanjeeva Reddy Nagar, Hyderabad, Telangana 500038</p>
            <p>Email: <a href="Varahidigi@gmail.com">varahidigi@gmail.com</a></p>
            <p>Phone: +91-9490242868</p>
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.624720298146!2d78.44436031442563!3d17.439684988056405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99a4a01081c1%3A0x839f8495f997b9f1!2s17.439619%2C%2078.446549!5e0!3m2!1sen!2sin!4v1627055805454!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy">
              </iframe>
            </div>
          </div>
        </div>
        <div className="contact-form-container">
          <h2>Contact Us</h2>
          <p>We’d love to hear from you. Use the form to contact us with any questions or comments, and we’ll get back to you as soon as possible. We try to respond to all inquiries within the same business day.</p>
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Full name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="form-group">
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                placeholder="Your Comment"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && <p className="error">{errors.message}</p>}
            </div>
            <div className="form-group checkbox">
              <input type="checkbox" id="agree" name="agree" required />
              <label htmlFor="agree">By submitting, I’m agreed to the Terms & Conditions</label>
            </div>
            <button type="submit" className="serviceButton">Send Message</button>
          </form>
        </div>
        {isEmailSent && (
          <div className="alert-container">
            <Alert severity={alertSeverity}>{alertMessage}</Alert>
          </div>
        )}
      </div>
      <Footer />
      <ScrollBar />
    </div>
  );
};

export default Contact;
